export const IMAGE_F = {
  blur: "blur",
  brighten: "brighten",
  colorize: "colorize",
  contrast: "contrast",
  darken: "darken",
  desaturate: "desaturate",
  edge_detect: "edge detect",
  emboss: "emboss",
  flip: "flip",
  invert: "invert",
  opacity: "opacity",
  pixelate: "pixelate",
  sepia: "sepia",
  sharpen: "sharpen",
  sketch: "sketch",
};

export const getImage = ({ m, obj }) => {
  if (
    m === "thumbnail" &&
    obj?.styles?.find(({ style }) => style === "thumbnail")?.path
  ) {
    return `${
      obj.styles.find(({ style }) => style === "thumbnail")?.path
    }`;
  } else if (
    m === "bigger" &&
    obj?.styles?.find(({ style }) => style === "bigger")?.path
  ) {
    return `${obj?.styles?.find(({ style }) => style === "bigger")?.path}`;
  } else {
    return `${obj.path}`;
  }
};
