import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

// import CrewMember from "components/atoms/CrewMember";
import OfferTileGrid from "components/molecules/OfferTileGrid";
import TestimonialsCarousel from "components/organisms/TestimonialsCarousel";

// import backgroundImage from "assets/images/gradient.svg";
import { ReactComponent as ReadingSVG } from "assets/images/reading.svg";
import { ReactComponent as CountriesOverHead } from "assets/images/country-flags-over-head.svg";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import SectionParagraph from "components/atoms/SectionParagraph";
import AnimatedSmallHeading from "components/atoms/AnimatedSmallHeading";

// import { breakPoints, maxWidthStyle } from "constant";
import ArticlePreview from "components/atoms/ArticlePreview";
import Axios from "axios";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
// import AnimateIt from "HOC/AnimateIt";
import { Link } from "react-router-dom";
// import PrimaryButton from "components/atoms/PrimaryButton";
import { getImage } from "utils/Api";
import { breakPoints, maxWidthStyle } from "constant";
import { Helmet } from "react-helmet";

const Home = () => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await Axios.get(
        "/api/collections/get/artykuly?sort[_created]=-1",
      );

      const { entries } = data;
      setArticles(entries);
    })();
  }, []);

  return (
    <RouteAnimWrapper>
      <Helmet><title>{t("home.hero_text")}</title></Helmet>
      <HeroSection>
        <HeroTextWrapper>
          <HeroText>{t("home.hero_text")}</HeroText>
          <HeroButtonsWrapper>
            <StyledHeroLink
              to="/test"
              style={{
                marginRight: "1rem",
              }}
            >
              {t("common.test_online").toUpperCase()}
            </StyledHeroLink>
            <StyledHeroLink to="/kurs-zapis">
              {t("common.sign_up").toUpperCase()}
            </StyledHeroLink>
          </HeroButtonsWrapper>
        </HeroTextWrapper>
        <ReadingSVGWrapper>
          <ReadingSVG />
        </ReadingSVGWrapper>
      </HeroSection>
      <OfferSection>
        <AnimatedSectionHeading>{t("home.heading1")}</AnimatedSectionHeading>{" "}
        <OfferTileGrid />
      </OfferSection>
      <LanguagesSection
        style={{ textAlign: "justify", paddingBottom: "3.5rem" }}
      >
        <AnimatedSectionHeading style={{ marginBottom: 0 }}>
          {t("home.heading2")}
        </AnimatedSectionHeading>
        <SectionParagraph style={{ marginBottom: 0 }}>
          {t("home.text1")}
        </SectionParagraph>
        <AnimatedSmallHeading>{t("home.heading3")}</AnimatedSmallHeading>
        <SvgLanguagesWrapper>
          <CountriesWrapper>
            <CountriesOverHead />
          </CountriesWrapper>
          <LanguagesWrapper>
            <Language>{t("home.languages.eng")}</Language>
            <Language>{t("home.languages.ger")}</Language>
            {/* <Language>{t("home.languages.pol")}</Language> */}
            {/* <Language>{t("home.languages.rus")}</Language>
            <Language>{t("home.languages.fr")}</Language>
            <Language>{t("home.languages.es")}</Language> */}
          </LanguagesWrapper>
        </SvgLanguagesWrapper>
        <SectionParagraph style={{ margin: 0, paddingTop: "1rem" }}>
          {t("home.text2")}
        </SectionParagraph>
      </LanguagesSection>
      {/* <CrewSection>
        <AnimateIt
        inViewMargin="-200px"
          initial={{ x: "-40%", opacity: 0 }}
          variants={{
            visible: () => ({
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.2,
                duration: 1.5,
              },
            }),
          }}
        >
          <CircleBackgroundWrapper>
            <svg
              viewBox="0 0 400 400"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="200"
                cy="200"
                r="175"
                stroke="#F0F0F0"
                strokeWidth="50"
              />
            </svg>
          </CircleBackgroundWrapper>
        </AnimateIt>
        <AnimateIt
        inViewMargin="-200px"
          initial={{ x: "40%", opacity: 0 }}
          variants={{
            visible: () => ({
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.2,
                duration: 1.5
              },
            }),
          }}
        >
          <CircleBackgroundWrapper top right>
            <svg
              viewBox="0 0 400 400"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="200"
                cy="200"
                r="175"
                stroke="#F0F0F0"
                strokeWidth="50"
              />
            </svg>
          </CircleBackgroundWrapper>
        </AnimateIt>
        <AnimatedSectionHeading style={{ padding: "0 1rem" }}>
          Poznaj nasz zespół profesjonalistów
        </AnimatedSectionHeading>
        <CrewGrid>
          {CREW.map(({ name, text, image }, index) => (
            <CrewMember key={name} name={name} text={text} image={image} />
          ))}
        </CrewGrid>
      </CrewSection> */}
      <TestimonialsSection>
        <TestimonialsCarousel />
      </TestimonialsSection>
      {!!articles?.length && (
        <ArticlesSection>
          <AnimatedSectionHeading style={{width: '100%'}}>{t("home.heading4")}</AnimatedSectionHeading>
          <ArticlesGrid>
            {articles
              .filter((item, index) => index < 4)
              .map(
                ({
                  _id,
                  tytul,
                  tytul_en,
                  opis,
                  opis_en,
                  _created,
                  zdjecie,
                }) => (
                  <ArticlePreview
                    key={_id}
                    id={_id}
                    title={tytul}
                    title_en={tytul_en}
                    text={opis}
                    text_en={opis_en}
                    date={new Date(_created * 1000)}
                    image={
                      zdjecie?.path ? getImage({ m:"thumbnail",obj: zdjecie }) : null
                    }
                  />
                ),
              )}
          </ArticlesGrid>
        </ArticlesSection>
      )}
    </RouteAnimWrapper>
  );
};

const HeroButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  @media (min-width: ${breakPoints.beforeTablet}) {
    font-size: 1rem;
    margin-top: 2rem;
  }
`;

const StyledHeroLink = styled(Link)`
  border: none;
  display: block;
  border-radius: 4px;
  color: white;
  text-align: center;
  background-color: ${({ theme }) => theme.txtColor};
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  font-weight: 600;
  text-transform: uppercase;
  :hover {
    transform: translateY(-0.2rem);
    opacity: 0.8;
  }
  font-size: 0.75rem;
  flex: 1;
  max-width: 8rem;
  padding: 0.5rem;

  @media (min-width: ${breakPoints.beforeTablet}) {
    font-size: 1rem;
    max-width: 10rem;
  }
`;

const HeroSection = styled.section`
  min-height: 75vw;
  position: relative;
  ${maxWidthStyle};

  @media (min-width: ${breakPoints.beforeTablet}) {
    min-height: 65vw;
  }

  @media (min-width: ${breakPoints.tablet}) {
    min-height: 37.5rem;
  }
`;

const ReadingSVGWrapper = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: -6rem auto 0;
  svg {
    width: 100%;
    height: auto;
  }

  @media (min-width: ${breakPoints.beforeTablet}) {
    margin: -10rem auto 0;
  }
`;

const HeroTextWrapper = styled.div`
  margin: 1rem auto;
  position: relative;
  z-index: 1;
`;

const HeroText = styled.h1`
  font-size: 1.4rem;
  text-align: center;
  white-space: pre-line;
  color: ${({ theme }) => theme.secondary};

  @media (min-width: ${breakPoints.beforeTablet}) {
    line-height: 1.2;
    font-size: 2.4rem;
  }

  @media (min-width: ${breakPoints.tablet}) {
    line-height: 1.05;
    font-size: 3.6rem;
  }
`;

const CountriesWrapper = styled.div`
  flex: 1;
  margin: 0 1rem;
  max-width: 17.5rem;
  svg {
    width: 100%;
    height: auto;
  }
`;

const OfferSection = styled.section`
  padding: 3rem 1.5rem;
  ${maxWidthStyle};
`;

// const CrewSection = styled.section`
//   padding: 0;
//   position: relative;
//   padding-bottom: 3.5rem;
//   ${maxWidthStyle};
// `;

// const CrewGrid = styled.div`
//   display: grid;
//   grid-template-columns: 1fr;

//   @media (min-width: 26.5em) {
//     grid-template-columns: 1fr 1fr;
//   }

//   @media (min-width: 68em) {
//     grid-template-columns: 1fr 1fr 1fr 1fr;
//   }
// `;

const SvgLanguagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (min-width: ${breakPoints.beforeTablet}) {
    margin-top: 2rem;
  }
`;

const LanguagesSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 1.5rem;
`;

const LanguagesWrapper = styled.div`
  flex: 1;
  padding: 0.5rem;
  text-align: center;
  max-width: 12rem;
`;

const Language = styled.h4`
  font-size: 1rem;
  line-height: 1.7;
  color: ${({ theme }) => theme.secondary};

  @media (min-width: ${breakPoints.beforeTablet}) {
    font-size: 1.3rem;
  }
`;

/* TESTIMONIALS SECTION */

const TestimonialsSection = styled.section`
  position: relative;
  background-image: linear-gradient(45deg,#e9e9e9,#b2b2b2);
`;

// const CircleBackgroundWrapper = styled(motion.div)`
//   position: absolute;
//   z-index: -1;
//   ${({ top }) => (top ? "top: 12.5%" : "bottom: 10%")};
//   ${({ right }) => (right ? "right: -30%" : "left: -30%")};

//   @media (min-width: ${breakPoints.tablet}) {
//     ${({ top }) => (top ? "top: 12.5%" : "bottom: 10%")};
//     ${({ right }) => (right ? "right: -17%" : "left: -17%")};
//   }

//   @media (min-width: 68em) {
//     ${({ top }) => (top ? "top: 12.5%" : "bottom: 10%")};
//     ${({ right }) => (right ? "right: -37%" : "left: -37%")};
//   }

//   svg {
//     width: 25rem;
//     height: 25rem;

//     @media (min-width: 68em) {
//       width: 60vw;
//       height: 60vw;
//       max-width: 50rem;
//       max-height: 50rem;
//     }
//   }
// `;

const ArticlesSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5rem;
  ${maxWidthStyle};
`;

const ArticlesGrid = styled.div`
  display: grid;
  padding: 0.5rem 1.5rem;
  grid-template-columns: 1fr;
  grid-gap: 1.5rem;

  @media (min-width: ${breakPoints.beforeTablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 68em) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export default Home;
