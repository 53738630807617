import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const AnimateIt = ({ wrap, children, inViewMargin, ...rest }) => {
  const [ref, inView] = useInView({
    rootMargin: `${inViewMargin || "-75px"} 0px`,
  });

  if (wrap) {
    return (
      <motion.div
        ref={ref}
        animate={inView ? "visible" : ""}
        initial={{ y: "100%", opacity: 0 }}
        variants={{
          visible: () => ({
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.2,
            },
          }),
        }}
        {...rest}
      >
        {children}
      </motion.div>
    );
  }
  return (
    <>
      {React.cloneElement(children, {
        ref: ref,
        initial: { y: "100%", opacity: 0 },
        animate: inView ? "visible" : "",
        variants: {
          visible: () => ({
            y: 0,
            opacity: 1,
            transition: {
              delay: 0.2,
            },
          }),
        },
        ...rest,
      })}
    </>
  );
};

export default AnimateIt;
