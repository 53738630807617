

import React from "react";
import styled from "styled-components";
import { breakPoints } from "constant";
import { useInView } from "react-intersection-observer";
import { SplitText } from "./SplitText";

const AnimatedSectionHeading = ({ children, ...rest }) => {
   const [ref, inView] = useInView({
     rootMargin: "-75px 0px",
   });

  return (
    <SectionHeadingH2 ref={ref} {...rest}>
      <SplitText
        initial={{ y: "100%" }}
        animate={inView ? "visible" : ""}
        variants={{
          visible: (i) => ({
            y: 0,
            transition: {
              delay: i * 0.02,
            },
          }),
          hidden: (i) => ({
            y: '100%',
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
      >
        {children}
      </SplitText>
    </SectionHeadingH2>
  );};

const SectionHeadingH2 = styled.h2`
  font-size: 2.5rem;
  margin: 1.5rem auto;
  max-width: 44rem;
  text-align: center;
  color: ${({ theme }) => theme.secondary};

  @media (min-width: ${breakPoints.beforeTablet}) {
    font-size: 3.2rem;
  }
`;

export default AnimatedSectionHeading;