import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { closeMenu } from 'store/settings/settings.slice';

const Backdrop = () => {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector(({ settings }) => settings);

  const hideSideDrawerHandler = () => {
    dispatch(closeMenu());
  };

  return (
    <BackdropWrapper onClick={hideSideDrawerHandler} visible={isMenuOpen} />
  );
};

const BackdropWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.4);
  top: 0;
  z-index: 10;
  left: 0;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  transition: opacity 0.3s linear, visibility 0.3s linear;
`;

export default Backdrop;
