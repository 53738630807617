import React, { useState } from "react";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import styled from "styled-components";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import PrimaryButton from "components/atoms/PrimaryButton";
import { maxWidthStyle, breakPoints } from "constant";
import Input from "components/atoms/Input";
import { ReactComponent as MailboxSVG } from "assets/images/mail_box.svg";
import Loader from "components/atoms/Loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const SignUp = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    register: userRegister,
    handleSubmit: userHandleSubmit,
    errors: userErrors,
  } = useForm();
  const [sending, setSending] = useState(false);

  const sendEmail = (data) => {
    setSending(true);
    Axios.post("/sign-up", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        history.push("/");
        toast("🚀 Pomyślnie wysłano E-Mail");
      })
      .catch(() => {
        toast("Coś poszło nie tak...", { type: "error" });
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{t("sign_up.heading1")}</title>
      </Helmet>
      <AnimatedSectionHeading
        style={{ padding: "0 1.5rem", lineHeight: "1.1" }}
        as="h1"
      >
        {t("sign_up.heading1")}
      </AnimatedSectionHeading>
      <Section>
        <SendMailWrapper>
          <UserForm onSubmit={userHandleSubmit(sendEmail)}>
            <UserFormGrid>
              <Input
                label={t("common.first_name")}
                error={userErrors.first_name}
                placeholder="Jan"
                type="text"
                name="first_name"
                register={userRegister({
                  required: t("validation.required"),
                })}
              />
              <Input
                label={t("common.last_name")}
                error={userErrors.last_name}
                placeholder="Kowalski"
                type="text"
                name="last_name"
                register={userRegister({
                  required: t("validation.required"),
                })}
              />
              <Input
                label={t("common.age")}
                error={userErrors.age}
                placeholder="25"
                maxLength="2"
                max="99"
                min="1"
                type="number"
                name="age"
                register={userRegister({
                  required: t("validation.required"),
                  pattern: {
                    value: /^[1-9][0-9]?$/,
                    message: t("validation.age"),
                  },
                })}
              />
              <Input
                label={t("common.email")}
                error={userErrors.email}
                placeholder="example@mail.com"
                type="email"
                name="email"
                register={userRegister({
                  required: t("validation.required"),
                  pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: t("validation.email"),
                  },
                })}
              />
              <Input
                label={t("common.phone_number")}
                error={userErrors.phone}
                placeholder="123456789"
                maxLength="9"
                type="tel"
                name="phone"
                register={userRegister({
                  pattern: {
                    value: /[0-9]{9}/,
                    message: t("validation.phone_number"),
                  },
                })}
              />
            </UserFormGrid>
            {sending ? (
              <Loader style={{ marginTop: "1.5rem", marginLeft: "1rem" }} />
            ) : (
              <PrimaryButton style={{ marginTop: "1.5rem" }} type="submit">
                {t("common.send").toUpperCase()}
              </PrimaryButton>
            )}
          </UserForm>
          <MailboxSVGWrapper>
            <MailboxSVG height="100%" width="100%" />
          </MailboxSVGWrapper>
        </SendMailWrapper>
      </Section>
    </RouteAnimWrapper>
  );
};

const Section = styled.section`
  padding: 0 1.5rem 1.5rem;
  ${maxWidthStyle};
`;

const MailboxSVGWrapper = styled.div`
  width: 100%;
  max-width: 30rem;
  padding: 1.25rem;
  @media (min-width: 45em) {
    padding: 2.5rem;
    width: 40%;
  }

  @media (min-width: ${breakPoints.laptop}) {
    width: 50%;
  }
`;

const SendMailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const UserFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const UserForm = styled.form`
  width: 100%;
  @media (min-width: 45em) {
    width: 60%;
  }
  @media (min-width: ${breakPoints.laptop}) {
    width: 50%;
  }
`;

export default SignUp;
