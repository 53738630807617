import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { maxWidthStyle } from "constant";
import { useTranslation } from "react-i18next";

const FooterComponent = () => {
  const { t } = useTranslation();

  const scroll = () => {
    setTimeout(() => {
      const element = document.querySelector(window.location.hash);
      if (element) {
        const y = element.getBoundingClientRect().top - 60 + window.pageYOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <Footer>
      <CircleBackgroundWrapper>
        <svg
          version="1.1"
          viewBox="0 0 500 500"
          preserveAspectRatio="xMinYMin meet"
        >
          <circle cx="250" cy="250" r="250" />
        </svg>
      </CircleBackgroundWrapper>
      <CircleBackgroundWrapper>
        <svg
          version="1.1"
          viewBox="0 0 500 500"
          preserveAspectRatio="xMinYMin meet"
        >
          <circle cx="250" cy="250" r="185" stroke="#FFFFFF" strokeWidth="65" />
        </svg>
      </CircleBackgroundWrapper>
      <FooterLinksGrid>
        <FooterItemsWrapper>
          <FooterGroupHeading>{t("footer.menu")}</FooterGroupHeading>
          <FooterLink to="/">{t("menu_list.item1")}</FooterLink>
          <FooterLink to="/o-nas">{t("menu_list.item2")}</FooterLink>
          <FooterLink to="/oferta">{t("menu_list.item3")}</FooterLink>
          <FooterLink to="/dla-firm">{t("menu_list.item4")}</FooterLink>
          <FooterLink to="/cennik">{t("menu_list.item5")}</FooterLink>
          <FooterLink to="/aktualnosci/strona/1">{t("menu_list.item6")}</FooterLink>
          <FooterLink to="/kontakt">{t("menu_list.item7")}</FooterLink>
        </FooterItemsWrapper>
        <FooterItemsWrapper>
          <FooterGroupHeading>{t("footer.offer")}</FooterGroupHeading>
          <FooterLink onClick={scroll} to="/oferta#kursy-grupowe">
            {t("offer_links.item1")}
          </FooterLink>
          <FooterLink onClick={scroll} to="/oferta#dla-najmlodszych">
            {t("offer_links.item2")}
          </FooterLink>
          <FooterLink onClick={scroll} to="/oferta#dla-dzieci-i-mlodziezy">
            {t("offer_links.item3")}
          </FooterLink>
          <FooterLink onClick={scroll} to="/oferta#kurs-dla-doroslych">
            {t("offer_links.item4")}
          </FooterLink>
          <FooterLink onClick={scroll} to="/oferta#tryb-indywidualny">
            {t("offer_links.item5")}
          </FooterLink>
          <FooterLink onClick={scroll} to="/oferta#tryb-indywidualny">
            {t("offer_links.item6")}
          </FooterLink>
          <FooterLink onClick={scroll} to="/oferta#kursy-online">
            {t("offer_links.item7")}
          </FooterLink>
          <FooterLink
            onClick={scroll}
            to="/oferta#kurs-jezyka-polskiego-dla-obcokrajowcow"
          >
            {t("offer_links.item8")}
          </FooterLink>
        </FooterItemsWrapper>
        <FooterItemsWrapper>
          <FooterGroupHeading>{t("footer.sign_up")}</FooterGroupHeading>
          <FooterLink to="/test">{t("common.test_online")}</FooterLink>
          <FooterLink to="/kurs-zapis">{t("common.sign_up")}</FooterLink>
        </FooterItemsWrapper>
      </FooterLinksGrid>
    </Footer>
  );
};

const Footer = styled.footer`
  min-height: 20rem;
  padding: 2rem 0;
  position: relative;
  background-color: ${({ theme }) => theme.secondary};
`;

const CircleBackgroundWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 35rem;
  padding-bottom: 35rem;
  vertical-align: middle;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  opacity: 0.025;
  & svg {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    fill: #ffffff;
  }
`;

const FooterLinksGrid = styled.div`
  position: relative;
  z-index: 1;
  display: grid;
  grid-gap: 1.5rem 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  ${maxWidthStyle};

  @media (min-width: 25rem) {
    grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  }
`;

const FooterItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const FooterLink = styled(Link)`
  display: flex;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  font-size: 0.9rem;
  font-weight: 300;
  text-transform: uppercase;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  :hover {
    opacity: 0.6;
    transform: translateY(-0.2rem);
  }
`;

const FooterGroupHeading = styled.span`
  display: flex;
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
`;

export default FooterComponent;
