import React from 'react';
import styled from 'styled-components';
import PrimaryButton from './PrimaryButton';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const OfferTile = ({ heading, text, route }) => {
  const {t} = useTranslation();
  return (
    <Wrapper>
      <Top>{heading && <BigText>{heading}</BigText>}</Top>
      <Mid>{text && <Text>{text}</Text>}</Mid>
      <Bottom>
        <PrimaryButton
          style={{ display: "inline-block" }}
          as={NavLink}
          to={route}
        >
          {t("home.find_out_more")}
        </PrimaryButton>
      </Bottom>
    </Wrapper>
  );
};

const BigText = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.secondary};
`;

const Text = styled.span`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.txtColor};
`;

const Top = styled.div`
  width: 100%;
  padding: 1rem;
`;

const Mid = styled.div`
  width: 100%;
  flex: 1;
  padding: 0 1rem;
`;

const Bottom = styled.div`
  width: 100%;
  padding: 1rem;
  padding-top: 2rem;
`;

const Wrapper = styled.div`
  & button {
    background-color: ${({ theme }) => theme.primary};
  }
  background-color: white;
  box-shadow: 0 16px 24px -16px rgba(59, 142, 184, 0.25);

  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 1rem;
`;

export default OfferTile;
