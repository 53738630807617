import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { pl, enGB } from "date-fns/locale";
import { format } from "date-fns";
import { Redirect, useHistory } from "react-router-dom";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import { maxWidthStyle } from "constant";
import AnimateIt from "HOC/AnimateIt";
import { getImage } from "utils/Api";

const Article = () => {
  const history = useHistory();
  const [article, setArticle] = useState();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const {fromArticles = false} = history.location.state || {};
  const { t,i18n } = useTranslation();
  const activeLanguage = i18n?.language.substring(0, 2).toLowerCase() || "pl";

  const formattedDate = (date, dateFormat) =>
    format(date, dateFormat, {
      locale: activeLanguage === "pl" ? pl : enGB,
    });

  const pathArr = history.location.pathname.split("-");
  const id = pathArr.pop();

  const articleURL = `/api/collections/get/artykuly?filter[_id]=${id}`;

  const createMarkup = () => ({
    __html:
      activeLanguage === "pl" ? article.opis : article.opis_en || article.opis,
  });

  const strip = (html) => {
    var doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    (async () => {
      const { data } = await Axios.get(articleURL);

      const { entries } = data;
      if (!entries[0]) {
        setShouldRedirect(true);
      }
      setArticle(entries[0]);
    })();
  }, []);

  return (
    <RouteAnimWrapper>
      <Wrapper>
        {article?.tytul && article?.opis && (
          <Helmet titleTemplate="">
            <title>
              {activeLanguage === "pl"
                ? article.tytul
                : article.tytul_en || article.tytul}
            </title>
            <meta
              name="description"
              content={strip(
                activeLanguage === "pl"
                  ? article.opis
                  : article.opis_en || article.opis,
              )}
            />
          </Helmet>
        )}
        {shouldRedirect && <Redirect to="/aktualnosci" />}
        <AnimateIt
          wrap
          initial={{ x: "-5rem", opacity: 0 }}
          variants={{
            visible: () => ({
              x: 0,
              opacity: 1,
              transition: {
                delay: 0.2,
              },
            }),
          }}
        >
          <GoBack
            onClick={() => {
              if (fromArticles) {
                history.goBack();
              } else {
                history.push("/aktualnosci/strona/1");
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15.625"
              height="10"
              viewBox="0 0 25 16"
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="#1d386b"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <path d="M24 8H1M8 15L1 8l7-7" />
              </g>
            </svg>
            <span>{t("article.heading1")}</span>
          </GoBack>
        </AnimateIt>
        {article && (
          <ArticleWrapper>
            {article?.zdjecie?.path && (
              <img
                src={getImage({ obj: article.zdjecie, m: "bigger" })}
                style={{ width: "100%", height: "auto" }}
                alt=""
              />
            )}
            <header style={{ marginBottom: "1.5rem" }}>
              <AnimatedSectionHeading
                as="h1"
                style={{
                  margin: 0,
                  paddingTop: "1rem",
                  fontSize: "2rem",
                  fontWeight: "600",
                  textAlign: "left",
                  lineHeight: 1.1,
                }}
              >
                {activeLanguage === "pl"
                  ? article.tytul
                  : article.tytul_en || article.tytul}
              </AnimatedSectionHeading>
              <DateComponent
                dateTime={formattedDate(article._created * 1000, "yyyy-MM-dd")}
              >
                {formattedDate(article._created * 1000, "dd MMM yyyy")}
              </DateComponent>
            </header>
            <Content dangerouslySetInnerHTML={createMarkup()} />
          </ArticleWrapper>
        )}
      </Wrapper>
    </RouteAnimWrapper>
  );
};

const DateComponent = styled.time`
  margin: 0 0 1rem 0;
  display: block;
  color: ${({ theme }) => theme.txtColor};
`;

const GoBack = styled.button`
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400;
  font-size: 1.4rem;
  padding: 1rem 2rem 1rem 1rem;
  color: ${({ theme }) => theme.secondary};
  & span {
    padding-left: 0.5rem;
  }
  &:hover {
    opacity: 0.8;
  }
`;

const Content = styled.p`
  margin: 0;
  & a {
    color: ${({ theme }) => theme.secondary};
    font-weight: 600;
  }

  p {
    margin: 0 0 1.75rem 0;
    color: ${({ theme }) => theme.txtColor};
  }

  & a:hover {
    opacity: 0.8;
  }
`;

const ArticleWrapper = styled.article`
  margin: 0 auto;
  padding: 0 1.5rem;
  max-width: 44rem;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  width: 100%;
  ${maxWidthStyle};
`;

export default Article;
