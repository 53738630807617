import React from "react";
import styled from "styled-components";
import { useState } from "react";

const Input = ({ register, label, error, errors, name, className, style, ...rest }) => {
  const [isFocused, setIsFocused] = useState();

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  return (
    <StyledLabel style={style} className={className} error={!!error} isFocused={isFocused}>
      {!!label && label}
      <StyledInput
        {...rest}
        name={name}
        ref={register}
        onFocus={handleFocus}
        onBlur={handleBlur}
        error={!!error}
      />
      {!!error && <ErrorMessage>{error.message}</ErrorMessage>}
    </StyledLabel>
  );
};

const ErrorMessage = styled.span`
  display: block;
  height: 1.125rem;
`;

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${({theme, isFocused}) => isFocused ? theme.secondary : theme.txtColor};
  
  ${({ error, theme }) =>
    error
      ? `
    color: ${theme.error};
  `
      : ``};
`;

const StyledInput = styled.input`
  background-color: transparent;
  border: 1px solid
    ${({ theme, error }) => (error ? theme.error : theme.txtColor)};
  padding: 0.5rem 0.75rem;
  margin-top: 0.25rem;
  border-radius: 0.25rem;
  outline: none;
  outline-color: transparent;
  outline-width: 0px;
  transition: box-shadow 0.2s ease-in-out;
  width: 100%;
  &:focus {
    box-shadow: 0 2px 12px -6px rgba(59, 142, 184, 0.5);
    border: 1px solid
      ${({ theme, error }) => (error ? theme.error : theme.secondary)};
  }
`;

export default Input;
