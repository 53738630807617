import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ContactUsSvg } from 'assets/images/contact_us.svg';

import AnimatedSectionHeading from 'components/atoms/AnimatedSectionHeading';
import Loader from 'components/atoms/Loader';
import RouteAnimWrapper from 'components/atoms/RouteAnimWrapper';
import { maxWidthStyle, breakPoints } from 'constant';
import { Helmet } from 'react-helmet';

const Contact = () => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(true);

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{t("contact.heading1")}</title>
      </Helmet>
      <AnimatedSectionHeading as="h1" style={{ padding: "0 1.5rem" }}>
        {t("contact.heading1")}
      </AnimatedSectionHeading>
      <ContactUsSvg
        width="90%"
        height="100%"
        style={{ display: "block", margin: "0 auto", maxWidth: "55em" }}
      />
      <MainContact style={{ padding: "0 1.5rem" }}>
        <AnimatedSectionHeading
          style={{ fontSize: "1.6rem", textAlign: "left", maxWidth: "unset" }}
        >
          {t("contact.heading2")}
        </AnimatedSectionHeading>
        <address>
          <ContactText>47-232 Kędzierzyn-Koźle</ContactText>
          <ContactText>Aleja Jana Pawła II 41 A</ContactText>
          <ContactText>
            <Anchor href="mailto:biuro@lang.pl">biuro@lang.pl</Anchor>
          </ContactText>
          <ContactText style={{ marginTop: "1.5rem" }}>
            {t("contact.text1")}{" "}
            <span style={{ color: "#D4182A", fontWeight: "600" }}>
              {t("contact.bold_text1")}
            </span>
            *
          </ContactText>
          <ContactText style={{ fontSize: "0.75rem", marginTop: "0.25rem" }}>
            * {t("contact.text2")}
          </ContactText>
        </address>
      </MainContact>
      <OtherContacts>
        <OtherContactsGrid>
          <ContactGridItem>
            <ContactName>Dyrektor szkoły</ContactName>
            <ContactText>Beata Piechulek</ContactText>
            <ContactText>
              <Anchor href="tel:538538347">538 538 347</Anchor>
            </ContactText>
            <ContactText>
              <Anchor href="mailto:lang@lang.pl">lang@lang.pl</Anchor>
            </ContactText>
          </ContactGridItem>

          <ContactGridItem>
            <ContactName>Zapisy</ContactName>
            <ContactText>
              <Anchor href="tel:509899309">509 899 309</Anchor>
            </ContactText>
            <ContactText>
              <Anchor href="mailto:zapisy@lang.pl">zapisy@lang.pl</Anchor>
            </ContactText>
          </ContactGridItem>

          <ContactGridItem>
            <ContactName>Biuro Szkoły</ContactName>
            <ContactText>
              <Anchor href="tel:509899309">509 899 309</Anchor>
            </ContactText>
            <ContactText>
              <Anchor href="mailto:biuro@lang.pl">biuro@lang.pl</Anchor>
            </ContactText>
          </ContactGridItem>

          {/* <ContactGridItem>
            <ContactName style={{ marginBottom: 0 }}>
              Biuro tłumaczeń
            </ContactName>
            <ContactName>Tłumacz przysięgły j.&nbsp;angielskiego</ContactName>
            <ContactText>
              <Anchor
                rel="nofollow noreferrer noopener"
                target="_blank"
                href="https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/translator,1378.html"
              >
                mgr Roman Gardeła
              </Anchor>
            </ContactText>
            <ContactText>
              <Anchor href="tel:774834729">77 48 34 729</Anchor>
            </ContactText>
            <ContactText>
              <Anchor href="mailto:tlumaczenia@lang.pl">
                tlumaczenia@lang.pl
              </Anchor>
            </ContactText>
          </ContactGridItem> */}

          {/* <ContactGridItem>
            <ContactName>Uniwersytet dziecięcy UNIKIDS</ContactName>
            <ContactText style={{ marginBottom: '1rem' }}>
              Oddział Gliwice
            </ContactText>
            <ContactText>
              <Anchor href="tel:774838297">77 483 82 97</Anchor>
            </ContactText>
            <ContactText>
              <Anchor href="mailto:gliwice@unikids.pl">
                gliwice@unikids.pl
              </Anchor>
            </ContactText>
            <ContactText>Koordynator</ContactText>
            <ContactText>
              <Anchor href="tel:506053066">506 053 066</Anchor>
            </ContactText>
          </ContactGridItem> */}
        </OtherContactsGrid>
      </OtherContacts>
      <MapOuter>
        {loading && (
          <MapLoadingWrapper>
            <Loader />
          </MapLoadingWrapper>
        )}
        <MapInner>
          <iframe
            width="100%"
            height="400px"
            title="Google Maps"
            id="gmap_canvas"
            onLoad={() => setLoading(false)}
            src="https://maps.google.com/maps?q=LANG%2C%20Aleja%20Jana%20Paw%C5%82a%20II%2041%2C%2047-232%20K%C4%99dzierzyn-Ko%C5%BAle&t=&z=17&ie=UTF8&iwloc=&output=embed"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
          />
        </MapInner>
      </MapOuter>
    </RouteAnimWrapper>
  );
};

const ContactName = styled.p`
  color: ${({ theme }) => theme.secondary};
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0 0 1rem 0;
`;

const ContactGridItem = styled.address`
  padding: 1rem 1.5rem;
  a {
    color: ${({ theme }) => theme.txtColor};
    font-weight: 400;
  }
  box-shadow: 0 16px 24px -16px rgba(59, 142, 184, 0.25);
  border-radius: 4px;
  background: #ffffff;
`;

const OtherContactsGrid = styled.div`
  display: grid;
  margin: 2rem 1.5rem;
  /* max-width: 40rem; */
  grid-template-columns: 1fr;
  grid-gap: 1.25rem;

  @media (min-width: ${breakPoints.beforeTablet}) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: ${breakPoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const Anchor = styled.a`
  color: ${({ theme }) => theme.primary};
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
  text-decoration: none;
`;

const MapLoadingWrapper = styled.div`
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
`;

const ContactText = styled.p`
  color: ${({ theme }) => theme.txtColor};
  margin: 0;
`;

const MapOuter = styled.div`
  position: relative;
  text-align: right;
  height: 35vh;
  min-height: 400px;
  width: 100%;
  box-shadow: 0 0px 24px -16px rgba(59, 142, 184, 0.25);
`;

const MapInner = styled.div`
  overflow: hidden;
  background: none !important;
  height: 35vh;
  min-height: 400px;
  width: 100%;
`;

const MainContact = styled.section`
  ${maxWidthStyle};
`;

const OtherContacts = styled.section`
  ${maxWidthStyle};
`;

export default Contact;
