import React from 'react';
import styled from 'styled-components';

const SectionParagraph = ({ children, ...rest }) => (
  <SectionParagraphP {...rest}>{children}</SectionParagraphP>
);

const SectionParagraphP = styled.p`
  font-size: 1rem;
  padding: 0 1.5rem 1rem;
  color: ${({ theme }) => theme.txtColor};
  max-width: 44rem;
  margin: 1rem auto;
  line-height: 1.6;
`;

export default SectionParagraph;
