import React from 'react';
import { useEmblaCarousel } from "embla-carousel/react";
import styled from 'styled-components';
import TestimonialsSlide from 'components/atoms/TestimonialsSlide';
import { maxWidthStyle } from 'constant';

const Testimonials = [
  {
    name: "Michał",
    role: "Student",
    text:
      "Rewelacyjna szkoła - uczę się z nimi już kilka lat i szczerze polecam.",
  },
  {
    name: "Wiktoria",
    role: "Student",
    text: "Nauka w porządku, szkoła też, ceny bardzo przystępne.",
  },
  {
    name: "Agata",
    role: "Student",
    text:
      "Polecam szkołę LANG, małe grupy, dzięki czemu jest dużo czasu na rozmowę w języku angielskim, co dla mnie jest bardzo istotne, nauczyciele mili i wyrozumiali.",
  },
  {
    name: "Igor",
    role: "Student",
    text:
      "Mam dobre zdanie o szkole LANG, chodzę na zajęcia indywidualne, a moja córka na grupowe, widzę u nas duże postępy w nauce angielskiego.",
  },
  {
    name: "Piotr",
    role: "Student",
    text:
      "Przygotowywałem się w szkole LANG przez ostatni rok do egzaminu maturalnego z angielskiego, lektorka super, tak mnie przygotowała, pomimo pandemii, że zdałem rozszerzenie na 93%, z czystym sumieniem polecam.",
  },
  {
    name: "Marcin",
    role: "Student",
    text:
      "Spróbowałem i nie żałuję, w końcu zacząłem dogadywać się po angielsku. Native speaker bardzo dobrze się sprawdził!",
  },
];

const TestimonialsCarousel = () => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    align: 'start',
    slidesToScroll: 1,
    loop: true,
  });

  const scrollNext = () => {
    embla.scrollNext();
  };

  const scrollPrev = () => {
    embla.scrollPrev();
  };

  return (
    <Container>
      <CarouselButton type="button" onClick={scrollPrev}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="16"
          viewBox="0 0 25 16"
        >
          <g
            fill="none"
            fillRule="evenodd"
            stroke="#1d386b"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path d="M24 8H1M8 15L1 8l7-7" />
          </g>
        </svg>
      </CarouselButton>
      <CarouselButton type="button" onClick={scrollNext}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="16"
          viewBox="0 0 25 16"
          transform="scale(-1,1)"
        >
          <g
            fill="none"
            fillRule="evenodd"
            stroke="#FFF"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path d="M24 8H1M8 15L1 8l7-7" />
          </g>
        </svg>
      </CarouselButton>
      <EmblaCarouselReact>
        <SlidesWrapper style={{ display: "flex" }}>
          {Testimonials.map(({ name, text, role }, index) => (
            <TestimonialsSlide
              key={name}
              name={name}
              text={text}
              role={role}
              index={index}
            />
          ))}
        </SlidesWrapper>
      </EmblaCarouselReact>
    </Container>
  );
};

const SlidesWrapper = styled.div`
  display: flex;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
`;

const Container = styled.div`
  transform: translateY(-3rem);
  ${maxWidthStyle};
`;

const CarouselButton = styled.button`
  margin: 0;
  margin-left: 0.8rem;
  height: 3rem;
  width: 3rem;
  border: none;
  background: none;
  font-size: 1.8rem;
  line-height: 2.5rem;
  display: block;
  color: #ffffff;
  text-align: center;
`;

export default TestimonialsCarousel;
