import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Ico from 'services/Ico';
import { useTranslation } from 'react-i18next';

const defaultMenuItem = ({ Icon, withIcons, route, text }) => (
  <MenuItemWrapper>
    <MenuItem exact activeClassName="active" to={route}>
      {withIcons && <Icon />}
      {text}
    </MenuItem>
  </MenuItemWrapper>
);

const MenuList = ({ row, renderItem, className, withIcons, style }) => {
  const MenuItemToRender = renderItem || defaultMenuItem;
  const {t} = useTranslation();

  const menuItems = [
    {
      id: 0,
      route: "/",
      text: t("menu_list.item1"),
      Icon: (props) => <Ico {...props} name="home" />,
    },
    {
      id: 1,
      route: "/o-nas",
      text: t("menu_list.item2"),
      Icon: (props) => <Ico {...props} name="about" />,
    },
    {
      id: 2,
      route: "/oferta",
      text: t("menu_list.item3"),
      Icon: (props) => <Ico {...props} name="offer" />,
    },
    {
      id: 3,
      route: "/dla-firm",
      text: t("menu_list.item4"),
      Icon: (props) => <Ico {...props} name="firm" />,
    },
    {
      id: 4,
      route: "/cennik",
      text: t("menu_list.item5"),
      Icon: (props) => <Ico {...props} name="price" />,
    },
    {
      id: 5,
      route: "/aktualnosci/strona/1",
      text: t("menu_list.item6"),
      Icon: (props) => <Ico {...props} name="news" />,
    },
    {
      id: 6,
      route: "/kontakt",
      text: t("menu_list.item7"),
      Icon: (props) => <Ico {...props} name="mail" />,
    },
  ];

  return (
    <MenuListUl style={style || {}} className={className} row={row}>
      {!!menuItems?.length &&
        menuItems.map(({ Icon, id, text, route }) => (
          <MenuItemToRender
            Icon={Icon}
            withIcons={withIcons}
            key={id}
            route={route}
            text={text}
          />
        ))}
    </MenuListUl>
  );
};

const MenuListUl = styled.ul`
  display: flex;
  list-style: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  align-items: center;
`;

const MenuItem = styled(NavLink)`
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.active {
    font-weight: 600;
  }
  transition: 0.2s transform ease-in-out;
  &:hover {
    transform: translateY(-0.25rem);
  }
`;

const MenuItemWrapper = styled.li`
  
`;

export default MenuList;
