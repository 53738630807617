import { createSlice } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  isMenuOpen: false,
  isInitialAnimationVisible: true,
};

const settings = createSlice({
  name: "settings",
  initialState: INITIAL_STATE,
  reducers: {
    openMenu: (state) => {
      state.isMenuOpen = true;
    },
    closeMenu: (state) => {
      state.isMenuOpen = false;
    },
    toggleMenu: (state) => {
      state.isMenuOpen = !state.isMenuOpen;
    },
    setIsInitialAnimationVisible: (state) => {
      state.isInitialAnimationVisible = false;
    },
  },
});

export const {
         openMenu,
         closeMenu,
         toggleMenu,
         setIsInitialAnimationVisible,
       } = settings.actions;

export default settings.reducer;
