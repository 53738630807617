import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const ChangeLanguageSquare = ({ lang }) => {
  const { i18n } = useTranslation();
  const changeLanguageHandler = () => {
    i18n.changeLanguage(lang, (err) => {
      if (err) return console.error('something went wrong loading', err);
    });
  };

  const activeLanguage = i18n?.language.substring(0, 2).toLowerCase() || 'pl';

  return (
    <Button active={lang === activeLanguage} onClick={changeLanguageHandler}>
      {lang}
    </Button>
  );
};

const Button = styled.button`
  border: 1px solid
    ${({ theme, active }) => (active ? theme.secondary : theme.txtColor)};
  background: none;
  color: ${({ theme, active }) => (active ? theme.secondary : theme.txtColor)};
  font-weight: ${({ active }) => (active ? '600' : '400')};
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  margin: 0.33rem;
  text-transform: uppercase;
  position: relative;
  cursor: pointer;
  padding: 0;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
`;

export default ChangeLanguageSquare;
