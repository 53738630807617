import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as NewsSvg } from "assets/images/news.svg";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import ArticlePreview from "components/atoms/ArticlePreview";
import Axios from "axios";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import { maxWidthStyle } from "constant";
import { useTranslation } from "react-i18next";
import { getImage } from "utils/Api";
import { useHistory, NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "components/atoms/Loader";
import { Helmet } from "react-helmet";

const LIMIT = 8;

const News = () => {
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState();
  const { id: page } = useParams() || {};
  const [pageCount, setPageCount] = useState();
  const history = useHistory();

  useEffect(() => {
    if ((pageCount !== undefined && page > pageCount) || page === undefined) {
      history.push("/aktualnosci/strona/1");
    }

    if (page) {
      (async () => {
        setLoading(true);
        try {
          const { data } = await Axios.get(
            `/api/collections/get/artykuly?sort[_created]=-1&skip=${
              LIMIT * (page - 1)
            }&limit=${LIMIT}`,
          );
          const { entries, total } = data;
          console.log({ t: Math.ceil(total / LIMIT) });
          setPageCount(Math.ceil(total / LIMIT));
          setArticles(entries);
        } catch {
          toast("Coś poszło nie tak... Odśwież stronę", { type: "error" });
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [history, page, pageCount]);

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{t("news.heading1")}</title>
      </Helmet>
      <TopSection>
        <NewsSvgWrapper>
          <NewsSvg width="75%" height="100%" />
        </NewsSvgWrapper>
        {loading ? (
          <Loader style={{ margin: "2rem auto" }} />
        ) : (
          <NewsWrapper>
            {!!articles?.length ? (
              <>
                <StyledSectionHeading>
                  {t("news.heading1")}
                </StyledSectionHeading>
                {articles.map(
                  ({
                    _id,
                    tytul,
                    tytul_en,
                    opis,
                    opis_en,
                    _created,
                    zdjecie,
                  }) => (
                    <ArticlePreview
                      fromArticles
                      key={_id}
                      id={_id}
                      title={tytul}
                      title_en={tytul_en}
                      text={opis}
                      text_en={opis_en}
                      date={new Date(_created * 1000)}
                      image={
                        zdjecie?.path
                          ? getImage({ m: "thumbnail", obj: zdjecie })
                          : null
                      }
                    />
                  ),
                )}
              </>
            ) : (
              <AnimatedSectionHeading>
                {t("news.no_articles")}
              </AnimatedSectionHeading>
            )}
          </NewsWrapper>
        )}
      </TopSection>
      {pageCount && (
        <Pagination>
          {Array.from({ length: pageCount }, (v, k) => (
            <ChangePageButton
              exact
              activeClassName="active"
              key={k + 1}
              to={`/aktualnosci/strona/${k + 1}`}
            >
              {k + 1}
            </ChangePageButton>
          ))}
        </Pagination>
      )}
    </RouteAnimWrapper>
  );
};

const Pagination = styled.section`
  padding: 2rem 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  ${maxWidthStyle};
`;

const ChangePageButton = styled(NavLink)`
  background-color: ${({ theme }) => theme.txtColor};
  color: #ffffff;
  width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.25rem;

  &.active {
    background-color: ${({ theme }) => theme.secondary};
  }

  &:hover {
    opacity: 0.8;
  }
`;

const StyledSectionHeading = styled(AnimatedSectionHeading)`
  grid-column: 1/-1;
  @media (min-width: 43em) {
    margin-top: -7rem;
  }
`;

const NewsWrapper = styled.section`
  padding: 2rem 1.5rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-auto-flow: dense;
`;

const NewsSvgWrapper = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopSection = styled.section`
  ${maxWidthStyle};
`;

export default News;
