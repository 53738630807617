import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {ReactComponent as ReceiptSvg} from 'assets/images/receipt.svg';
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import AnimatedSmallHeading from "components/atoms/AnimatedSmallHeading";
import SectionParagraph from "components/atoms/SectionParagraph";
import { breakPoints } from "constant";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import AnimateIt from "HOC/AnimateIt";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";


const PriceList = () => {
  const {t} = useTranslation();
  
  const TABLE_GROUP_HEADERS = [
    t("price_list.table1.heading1"),
    t("price_list.table1.heading2"),
    t("price_list.table1.heading3"),
  ];

  const TABLE_INDIVIDUAL_HEADERS = [
    t("price_list.table2.heading1"),
  ];
  
  const TABLE_PREPARE_HEADERS = [
    t("price_list.table1.heading2"),
    t("price_list.table1.heading3"),
  ];

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{t("price_list.heading1")}</title>
      </Helmet>
      <GroupSection>
        <TopWrapper>
          <AnimatedSectionHeading as="h1">
            {t("price_list.heading1")}
          </AnimatedSectionHeading>
          <ReceiptSvg />
        </TopWrapper>
        <AnimatedSmallHeading
          style={{ padding: "0 1.5rem", margin: "2rem 0 0 0" }}
        >
          {t("price_list.heading2")}
        </AnimatedSmallHeading>
        <ScrollX>
          <Table className="with-break primary-table">
            <thead>
              <tr>
                <th>{t("price_list.table1.type")}</th>
                <th>{TABLE_GROUP_HEADERS[0]}</th>
                <th>{TABLE_GROUP_HEADERS[1]}</th>
                <th>{TABLE_GROUP_HEADERS[2]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("price_list.table1.heading4")}</td>
                <td data-th={TABLE_GROUP_HEADERS[0]}>
                  {t("price_list.table1.row1.text1")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[1]}>
                  {t("price_list.table1.row1.text2")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[2]}>
                  {t("price_list.table1.row1.text3")}
                </td>
              </tr>
              <tr>
                <td>{t("price_list.table1.heading5")}</td>
                <td data-th={TABLE_GROUP_HEADERS[0]}>
                  {t("price_list.table1.row2.text1")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[1]}>
                  {t("price_list.table1.row2.text2")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[2]}>
                  {t("price_list.table1.row2.text3")}
                </td>
              </tr>
              <tr>
                <td>{t("price_list.table1.heading6")}</td>
                <td data-th={TABLE_GROUP_HEADERS[0]}>
                  {t("price_list.table1.row3.text1")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[1]}>
                  {t("price_list.table1.row3.text2")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[2]}>
                  {t("price_list.table1.row3.text3")}
                </td>
              </tr>
              <tr>
                <td>{t("price_list.table1.heading7")}</td>
                <td data-th={TABLE_GROUP_HEADERS[0]}>
                  {t("price_list.table1.row4.text1")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[1]}>
                  {t("price_list.table1.row4.text2")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[2]}>
                  {t("price_list.table1.row4.text3")}
                </td>
              </tr>
              <tr>
                <td>{t("price_list.table1.heading8")}</td>
                <td data-th={TABLE_GROUP_HEADERS[0]}>
                  {t("price_list.table1.row5.text1")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[1]}>
                  {t("price_list.table1.row5.text2")}
                </td>
                <td data-th={TABLE_GROUP_HEADERS[2]}>
                  {t("price_list.table1.row5.text3")}
                </td>
              </tr>
            </tbody>
          </Table>
        </ScrollX>
        <AnimatedSmallHeading style={{ padding: "0 1.5rem" }}>
          {t("price_list.prepare")}
        </AnimatedSmallHeading>
        <ScrollX>
          <Table className="with-break">
            <thead>
              <tr>
                <th>{t("price_list.table1.type")}</th>
                <th>{TABLE_PREPARE_HEADERS[0]}</th>
                <th>{TABLE_PREPARE_HEADERS[1]}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("price_list.table3.heading1")}</td>
                <td data-th={TABLE_PREPARE_HEADERS[0]}>
                  {t("price_list.table3.row1.text1")}
                </td>
                <td data-th={TABLE_PREPARE_HEADERS[1]}>
                  {t("price_list.table3.row1.text2")}
                </td>
              </tr>
              <tr>
                <td>{t("price_list.table3.heading2")}</td>
                <td data-th={TABLE_PREPARE_HEADERS[0]}>
                  {t("price_list.table3.row2.text1")}
                </td>
                <td data-th={TABLE_PREPARE_HEADERS[1]}>
                  {t("price_list.table3.row2.text2")}
                </td>
              </tr>
              <tr>
                <td>{t("price_list.table3.heading3")}</td>
                <td data-th={TABLE_PREPARE_HEADERS[0]}>
                  {t("price_list.table3.row3.text1")}
                </td>
                <td data-th={TABLE_PREPARE_HEADERS[1]}>
                  {t("price_list.table3.row3.text2")}
                </td>
              </tr>
            </tbody>
          </Table>
        </ScrollX>
        <IndividualSection>
          <AnimatedSmallHeading style={{ padding: "2rem 1.5rem 0", margin: 0 }}>
            {t("price_list.heading5")}
          </AnimatedSmallHeading>
          <ScrollX>
            <Table
              style={{ paddingBottom: 0 }}
              className="without-break primary-table"
            >
              <thead>
                <tr>
                  <th></th>
                  <th>{TABLE_INDIVIDUAL_HEADERS[0]}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t("price_list.table2.heading3")}</td>
                  <td data-th={TABLE_INDIVIDUAL_HEADERS[0]}>
                    {t("price_list.table2.group1.text1")}
                  </td>
                </tr>
                <tr>
                  <td>{t("price_list.table2.heading4")}</td>
                  <td data-th={TABLE_INDIVIDUAL_HEADERS[0]}>
                    {t("price_list.table2.group2.text1")}
                  </td>
                </tr>
                <tr>
                  <td>{t("price_list.table2.heading5")}</td>
                  <td data-th={TABLE_INDIVIDUAL_HEADERS[0]}>
                    {t("price_list.table2.group3.text1")}
                  </td>
                </tr>
              </tbody>
            </Table>
          </ScrollX>
          <SectionParagraph>{t("price_list.text6")}</SectionParagraph>
          <SectionParagraph>{t("price_list.text7")}</SectionParagraph>
        </IndividualSection>
        <AnimatedSmallHeading style={{ padding: "0 1.5rem" }}>
          {t("price_list.heading3")}
        </AnimatedSmallHeading>
        <SectionParagraph>{t("price_list.text1")}</SectionParagraph>
        <SectionParagraph>{t("price_list.text2")}</SectionParagraph>
        <SectionParagraph>{t("price_list.text3")}</SectionParagraph>
        <SectionParagraph>{t("price_list.text4")}</SectionParagraph>
        <SectionParagraph>{t("price_list.text5")}</SectionParagraph>
        <AnimatedSmallHeading style={{ padding: "0 1.5rem" }}>
          {t("price_list.heading4")}
        </AnimatedSmallHeading>
        <InstallmentGrid>
          <InstallmentItem>
            <InstallmentNumber>
              1 {t("price_list.instalment")}
            </InstallmentNumber>
            <InstallmentDate>{t("price_list.at_sign_up")}</InstallmentDate>
          </InstallmentItem>
          {Array.from({ length: 9 }, (v, k) => (
            <InstallmentItem key={String(k)}>
              <InstallmentNumber>
                {k + 2} {t("price_list.instalment")}
              </InstallmentNumber>
              <InstallmentDate>
                {t("price_list.pay_by")}
                <br />
                15.{k < 3 ? 10 + k : `0${k - 2}`}
              </InstallmentDate>
            </InstallmentItem>
          ))}
          <AnimateIt
            initial={{ x: "100%", opacity: 0 }}
            variants={{
              visible: () => ({
                x: 0,
                opacity: 1,
                transition: {
                  delay: 0.2,
                },
              }),
            }}
          >
            <InstallmentItem>
              <ZeroPercent>{t("price_list.zero_percent")}</ZeroPercent>
            </InstallmentItem>
          </AnimateIt>
        </InstallmentGrid>
        <AnimatedSmallHeading>{t("price_list.heading6")}</AnimatedSmallHeading>
        <SectionParagraph style={{ whiteSpace: "pre-line" }}>
          {t("price_list.text8")}{" "}
          <span style={{ whiteSpace: "nowrap" }}>{t("price_list.text9")}</span>
        </SectionParagraph>
      </GroupSection>
    </RouteAnimWrapper>
  );
};

const IndividualSection = styled.section`
  max-width: 75rem;
  margin: 0 auto;
`;

const InstallmentGrid = styled.div`
  padding: 1.5rem;
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: 1fr 1fr 1fr;

  @media (min-width: 47rem) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;

const ZeroPercent = styled.div`
  padding: 1rem;
  border-radius: 0.25rem;
  font-size: 1.4rem;
  text-align: center;
  background-color: ${({ theme }) => theme.txtColor};
  font-weight: 600;
  color: #ffffff;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InstallmentItem = styled(motion.div)`
  border-radius: 0.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  box-shadow: 0 16px 24px -16px rgba(59, 142, 184, 0.25);

  &:last-child {
    grid-column: 2/-1;
  }

  @media (min-width: 47rem) {
    &:last-child {
      grid-column: initial;
    }
  }
`;

const InstallmentNumber = styled.div`
  background-color: ${({theme}) => theme.txtColor};
  color: white;
  text-align: center;
  font-weight: 600;
  padding: 1rem 0.5rem;
`;

const InstallmentDate = styled.div`
  text-align: center;
  flex: 1;
  background-color: #ffffff;
  color: ${({ theme }) => theme.txtColor};
  padding: 1rem;
  white-space: pre-line;
`;

const GroupSection = styled.section`
  max-width: 75rem;
  margin: 0 auto;
`;

const ScrollX = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const TopWrapper = styled.div`
  max-width: 30rem;
  margin: 0 auto;
  svg {
    max-width: 10rem;
    width: 65%;
    height: auto;
    display: block;
    margin: -5rem auto 1rem 1.5rem;
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  padding: 1.5rem;
  display: inline-block;
  min-width: 18rem;
  width: 100%;
  &.primary-table {
    th, td:first-child {
      background-color: ${({ theme }) => theme.primary};
    }

    th, td {
     border-color: ${({ theme }) => theme.primary};
    }

  }

  th,
  td:first-child {
    font-weight: 600;
    color: white;
    background-color: ${({ theme }) => theme.secondary};
    text-align: left;
    text-transform: uppercase;
    font-size: 1rem;
  }

  th,
  td {
    border: 1px solid ${({ theme }) => theme.secondary};
    border-collapse: collapse;
    padding: 1rem 1.15rem;
    text-align: left;
  }

  tbody {
    display: block;
  }

  tbody tr:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  tbody tr {
    display: block;
    width: 100%;
  }

  th:not(:last-child),
  td:not(:last-child) {
    border-bottom: none;
  }

  td {
    text-align: left;
    color: ${({ theme }) => theme.txtColor};
    font-size: 1.25rem;
    display: block;
    &:before {
      content: "";
      vertical-align: middle;
      display: inline-block;
      height: 100%;
    }

    &:not(:first-child):before {
      content: attr(data-th) ": ";
      font-weight: 600;
      width: 100%;
      display: block;
      padding-bottom: 0.75rem;
      font-size: 1rem;
    }
  }

  tbody {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    @media (min-width: ${breakPoints.beforeTablet}) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: ${breakPoints.tablet}) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
  &.without-break {
    thead {
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px) !important;
      -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important;
      height: 1px !important;
      margin: -1px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
      white-space: nowrap !important;
    }
  }

  &.with-break {
    @media (min-width: ${breakPoints.beforeTablet}) {
      tr:last-child td:first-child {
        height: 4.8125rem;
      }
    }
    @media (max-width: 46.99rem) {
      thead {
        border: 0 !important;
        clip: rect(1px, 1px, 1px, 1px) !important;
        -webkit-clip-path: inset(50%) !important;
        clip-path: inset(50%) !important;
        height: 1px !important;
        margin: -1px !important;
        overflow: hidden !important;
        padding: 0 !important;
        position: absolute !important;
        width: 1px !important;
        white-space: nowrap !important;
      }
    }

    @media (min-width: 47rem) {
      min-width: 47rem;

      th,
      td {
        text-align: center;
        display: table-cell;
      }

      th:not(:last-child),
      td:not(:last-child) {
        border-bottom: 1px solid ${({ theme }) => theme.secondary};
      }

      &.primary-table {
        th:not(:last-child),
        td:not(:last-child) {
          border-bottom: 1px solid ${({ theme }) => theme.primary};
        }
      }

      th,
      td:first-child {
        font-size: 0.85rem;
        text-align: left;
      }

      td {
        &:not(:first-child):before {
          display: none;
        }
      }

      tbody {
        display: table-row-group;
      }

      tbody tr {
        display: table-row;
        margin-bottom: 0;
      }
    }
  }
`;

export default PriceList;
