import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const RouteAnimWrapper = ({children}) => {
  return (
    <Wrapper
      initial={{ opacity: 0, y: '2rem' }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0, duration: 0.2 }}
      exit={{ opacity: 0, y: '2rem' }}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
`;

export default RouteAnimWrapper;