import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMenu } from 'store/settings/settings.slice';

const HamburgerMenu = ({ className }) => {
  const dispatch = useDispatch();
  const { isMenuOpen } = useSelector(({ settings }) => settings);

  const openMenuHandler = () => {
    dispatch(toggleMenu());
  };

  return (
    <StyledBurger
      open={isMenuOpen}
      className={className}
      type="button"
      onClick={openMenuHandler}
    >
      <Wrapper open={isMenuOpen}>
        <span />
        <span />
        <span />
      </Wrapper>
    </StyledBurger>
  );
};

const StyledBurger = styled.button`
  width: 3rem;
  height: 3rem;
  position: fixed;
  z-index: 12;
  top: 1.25rem;
  background: none;
  border: none;
  appearance: none;
  right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
  ${({open}) => !open ? `
    &:hover span span {
      &:nth-child(1) {
        transform: translateY(-0.15rem);
      }

      &:nth-child(3) {
        transform: translateY(0.15rem);
      }
    }
  ` : ``}

`;

const Wrapper = styled.span`
  width: 2rem;
  height: 1.5rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;

  span {
    display: block;
    width: 2rem;
    height: 0.125rem;
    background-color: ${({ theme }) => theme.black};
    border-radius: 0.25rem;
    transform-origin: 5px;
    transition: transform 0.2s cubic-bezier(0.68, -0.55, 0.27, 1.55),
      opacity 0.2s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export default HamburgerMenu;
