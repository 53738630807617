import React from 'react';
import RouteAnimWrapper from 'components/atoms/RouteAnimWrapper';
import styled from 'styled-components';
import AnimatedSectionHeading from 'components/atoms/AnimatedSectionHeading';
import SectionParagraph from 'components/atoms/SectionParagraph';
import AnimatedSmallHeading from 'components/atoms/AnimatedSmallHeading';
import { ReactComponent as OfferSVG } from 'assets/images/offer.svg';
import { maxWidthStyle } from 'constant';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Offer = () => {
  const { t } = useTranslation();

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{t('offer.heading1')}</title>
      </Helmet>
      <AnimatedSectionHeading as="h1">
        {t('offer.heading1')}
      </AnimatedSectionHeading>
      <OfferSVGWrapper>
        <OfferSVG width="100%" height="100%" />
      </OfferSVGWrapper>
      <OfferSection>
        <SvgBlobWrapper style={{ zIndex: -1, top: '20rem', left: '-15rem' }}>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#F0F0F0"
              d="M41.5,-52.6C50.6,-41.8,52.5,-25.7,56.6,-9C60.7,7.7,66.9,25,62,38C57.1,51,41,59.8,23.8,66.6C6.6,73.4,-11.8,78.2,-25.6,72.3C-39.4,66.4,-48.5,49.9,-51.7,34.6C-54.9,19.3,-52.2,5.3,-46.7,-5.3C-41.3,-15.8,-33.2,-22.9,-25,-33.8C-16.8,-44.8,-8.4,-59.6,3.9,-64.2C16.2,-68.9,32.4,-63.4,41.5,-52.6Z"
              transform="translate(100 100)"
            />
          </svg>
        </SvgBlobWrapper>
        <SvgBlobWrapper style={{ zIndex: -1, top: '90rem', right: '-10rem' }}>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#F0F0F0"
              d="M52.3,-63.6C67.3,-49.8,78.6,-32.8,77.9,-16.7C77.2,-0.6,64.5,14.7,53.4,28.1C42.4,41.5,33,53.2,18.7,63.7C4.4,74.2,-14.7,83.6,-32.8,81C-50.8,78.4,-67.8,63.7,-67.6,47.2C-67.4,30.7,-50.1,12.5,-43.7,-5C-37.4,-22.4,-42,-39,-36.5,-54.5C-31.1,-70,-15.5,-84.4,1.6,-86.3C18.7,-88.2,37.3,-77.4,52.3,-63.6Z"
              transform="translate(100 100)"
            />
          </svg>
        </SvgBlobWrapper>
        <SvgBlobWrapper style={{ zIndex: -1, top: '160rem', left: '-10rem' }}>
          <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#F0F0F0"
              d="M39.6,-41.9C52,-36.8,63.1,-25,70,-8.9C76.9,7.1,79.6,27.3,69.4,35.5C59.2,43.6,36.1,39.8,17.2,46.3C-1.8,52.8,-16.6,69.6,-28.4,69.2C-40.2,68.7,-49,51.1,-57.7,34.2C-66.3,17.3,-74.9,1.3,-75.6,-16.6C-76.4,-34.6,-69.4,-54.4,-55.5,-59.2C-41.6,-64,-20.8,-53.8,-3.6,-49.5C13.6,-45.3,27.2,-47,39.6,-41.9Z"
              transform="translate(100 100)"
            />
          </svg>
        </SvgBlobWrapper>

        <AnimatedSmallHeading id="kursy-grupowe">
          {t('offer.heading2')}
        </AnimatedSmallHeading>
        <SectionParagraph>{t('offer.text1')}</SectionParagraph>
        {/* <SectionParagraph>{t("offer.text2")}</SectionParagraph> */}
      </OfferSection>
      <OfferSection>
        <AnimatedSmallHeading id="dla-najmlodszych">
          {t('offer.heading3')}
        </AnimatedSmallHeading>
        <SectionParagraph>{t('offer.text3')}</SectionParagraph>
        <SectionParagraph>{t('offer.text4')}</SectionParagraph>
      </OfferSection>

      <OfferSection>
        <AnimatedSmallHeading id="dla-dzieci-i-mlodziezy">
          {t('offer.heading4')}
        </AnimatedSmallHeading>
        <SectionParagraph>{t('offer.text5')}</SectionParagraph>
        <SectionParagraph>{t('offer.text6')}</SectionParagraph>
      </OfferSection>

      <OfferSection>
        <AnimatedSmallHeading id="kurs-dla-doroslych">
          {t('offer.heading5')}
        </AnimatedSmallHeading>
        <SectionParagraph>{t('offer.text7')}</SectionParagraph>
        <SectionParagraph>{t('offer.text8')}</SectionParagraph>
      </OfferSection>

      <OfferSection>
        <AnimatedSmallHeading id="tryb-indywidualny">
          {t('offer.heading6')}
        </AnimatedSmallHeading>
        <SectionParagraph>
          {t('offer.text9')} <strong>{t('offer.bold_text1')}</strong>{' '}
          {t('offer.text10')}
        </SectionParagraph>
      </OfferSection>

      <OfferSection>
        <AnimatedSmallHeading id="kursy-online">
          {t('offer.heading7')}
        </AnimatedSmallHeading>
        <SectionParagraph>{t('offer.text11')}</SectionParagraph>
      </OfferSection>
    </RouteAnimWrapper>
  );
};

const OfferSVGWrapper = styled.div`
  width: 65%;
  max-width: 30rem;
  margin: 2.5rem auto;
`;

const OfferSection = styled.section`
  text-align: justify;
  ${maxWidthStyle};
`;

const SvgBlobWrapper = styled.div`
  position: absolute;
  z-index: -1;

  svg {
    width: 25rem;
    height: 25rem;

    @media (min-width: 68em) {
      width: 60vw;
      height: 60vw;
      max-width: 50rem;
      max-height: 50rem;
    }
  }
`;

export default Offer;
