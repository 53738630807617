import React from "react";
import styled from "styled-components";
import { breakPoints } from "constant";
import { useInView } from "react-intersection-observer";
import { SplitText } from "./SplitText";

const AnimatedSmallHeading = ({ children, ...rest }) => {
  const [ref, inView] = useInView({
    rootMargin: "-75px 0px",
  });

  return (
    <SmallHeadingH3 ref={ref} {...rest}>
      <SplitText
        initial={{ y: "100%" }}
        animate={inView ? "visible" : ""}
        variants={{
          visible: (i) => ({
            y: 0,
            transition: {
              delay: i * 0.02,
            },
          }),
          hidden: (i) => ({
            y: "100%",
            transition: {
              delay: i * 0.02,
            },
          }),
        }}
      >
        {children}
      </SplitText>
    </SmallHeadingH3>
  );
};

const SmallHeadingH3 = styled.h3`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.secondary};
  text-align: center;
  width: 100%;
  margin-bottom: 1rem;
  @media (min-width: ${breakPoints.beforeTablet}) {
    font-size: 2rem;
  }
`;

export default AnimatedSmallHeading;
