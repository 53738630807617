import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from 'assets/images/logo-with-text-white.svg';
import HamburgerMenu from 'components/atoms/HamburgerMenu';
import MenuList from 'components/atoms/MenuList';
import { breakPoints, maxWidthStyle } from 'constant';
import ChangeLanguageSquare from 'components/atoms/ChangeLanguageSquare';
import { NavLink } from 'react-router-dom';

const Header = () => (
  <HeaderWrapper>
    <NavLink to="/">
      <Logo width="125" />
    </NavLink>
    <Nav>
      <StyledMenuList row />
    </Nav>
    <Right>
      <ChangeLanguageSquare lang="pl" />
      <ChangeLanguageSquare lang="en" />
    </Right>
    <StyledHamburgerMenu />
  </HeaderWrapper>
);

const Nav = styled.nav`
  width: 100%;
  height: 100%;
  display: none;

  @media (min-width: ${breakPoints.tablet}) {
    display: block;
  }
`;

const Right = styled.div`
  margin-left: auto;
  display: flex;
  margin-right: 2.5rem;
  @media (min-width: ${breakPoints.tablet}) {
    margin-right: 0;
  }
`;

const StyledMenuList = styled(MenuList)`
  justify-content: flex-end;
`;

const StyledHamburgerMenu = styled(HamburgerMenu)`
  display: block;

  & > span span {
    background-color: ${({ theme }) => theme.secondary};
  }

  @media (min-width: ${breakPoints.tablet}) {
    display: none;
  }
`;

const HeaderWrapper = styled.header`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 5.5rem;
  background: none;
  padding: 0 1.5rem;
  ${maxWidthStyle};
`;

export default Header;
