import React from "react";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import styled from "styled-components";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import SectionParagraph from "components/atoms/SectionParagraph";
import SchoolPicturesCarousel from "components/organisms/SchoolPicturesCarousel";
import AnimatedSmallHeading from "components/atoms/AnimatedSmallHeading";
import { ReactComponent as QuestionSVG } from "assets/images/question.svg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{t("about-us.heading1")}</title>
      </Helmet>
      <SvgBlobWrapper style={{ zIndex: -1, top: "10rem", left: "-15rem" }}>
        <svg
          width="50%"
          height="50%"
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="200" cy="200" r="175" stroke="#F0F0F0" strokeWidth="50" />
        </svg>
      </SvgBlobWrapper>
      <SvgBlobWrapper style={{ zIndex: -1, top: "70rem", right: "-12rem" }}>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#F0F0F0"
            d="M52.3,-63.6C67.3,-49.8,78.6,-32.8,77.9,-16.7C77.2,-0.6,64.5,14.7,53.4,28.1C42.4,41.5,33,53.2,18.7,63.7C4.4,74.2,-14.7,83.6,-32.8,81C-50.8,78.4,-67.8,63.7,-67.6,47.2C-67.4,30.7,-50.1,12.5,-43.7,-5C-37.4,-22.4,-42,-39,-36.5,-54.5C-31.1,-70,-15.5,-84.4,1.6,-86.3C18.7,-88.2,37.3,-77.4,52.3,-63.6Z"
            transform="translate(100 100)"
          />
        </svg>
      </SvgBlobWrapper>

      <SvgBlobWrapper style={{ zIndex: -1, top: "160rem", right: "-10rem" }}>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#F0F0F0"
            d="M31.1,-42.3C45.2,-32.5,64.9,-30,67.6,-21.9C70.3,-13.8,56,-0.1,50.6,16.5C45.3,33.1,48.8,52.6,41.9,61.2C35,69.8,17.5,67.4,-0.9,68.6C-19.3,69.8,-38.5,74.7,-48,66.9C-57.6,59.2,-57.4,38.9,-62.6,20.8C-67.7,2.8,-78.2,-13,-76,-26.3C-73.9,-39.6,-59.1,-50.3,-44.4,-59.9C-29.6,-69.4,-14.8,-77.8,-3.2,-73.5C8.5,-69.1,17,-52.1,31.1,-42.3Z"
            transform="translate(100 100)"
          />
        </svg>
      </SvgBlobWrapper>
      <SvgBlobWrapper style={{ zIndex: -1, top: "120rem", left: "-15rem" }}>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#F0F0F0"
            d="M51.4,-55.8C66.2,-48.7,77.6,-32.2,70.9,-20C64.1,-7.8,39.1,0.2,27.2,11C15.4,21.7,16.6,35.3,9.6,46.7C2.6,58.1,-12.6,67.2,-23.3,63.5C-33.9,59.8,-40,43.2,-43,29.2C-45.9,15.1,-45.7,3.5,-42.1,-5.9C-38.6,-15.3,-31.8,-22.5,-24.2,-30.9C-16.7,-39.3,-8.3,-48.8,5,-54.7C18.3,-60.6,36.5,-62.9,51.4,-55.8Z"
            transform="translate(100 100)"
          />
        </svg>
      </SvgBlobWrapper>
      <SvgBlobWrapper style={{ zIndex: -1, top: "290rem", right: "-10rem" }}>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#F0F0F0"
            d="M52.3,-63.6C67.3,-49.8,78.6,-32.8,77.9,-16.7C77.2,-0.6,64.5,14.7,53.4,28.1C42.4,41.5,33,53.2,18.7,63.7C4.4,74.2,-14.7,83.6,-32.8,81C-50.8,78.4,-67.8,63.7,-67.6,47.2C-67.4,30.7,-50.1,12.5,-43.7,-5C-37.4,-22.4,-42,-39,-36.5,-54.5C-31.1,-70,-15.5,-84.4,1.6,-86.3C18.7,-88.2,37.3,-77.4,52.3,-63.6Z"
            transform="translate(100 100)"
          />
        </svg>
      </SvgBlobWrapper>
      <SvgBlobWrapper style={{ zIndex: -1, top: "230rem", left: "-17rem" }}>
        <svg
          width="50%"
          height="50%"
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="200" cy="200" r="175" stroke="#F0F0F0" strokeWidth="50" />
        </svg>
      </SvgBlobWrapper>
      <SchoolPicturesCarousel />

      <AboutUsSection>
        <AnimatedSectionHeading as="h1">
          {t("about-us.heading1")}
        </AnimatedSectionHeading>
        <SectionParagraph>
          {t("about-us.text1")} <strong>{t("about-us.bold_text1")}</strong>{" "}
          {t("about-us.text2")}
        </SectionParagraph>
      </AboutUsSection>
      <WhyUsSection>
        <AnimatedSectionHeading>
          {t("about-us.heading2")}
        </AnimatedSectionHeading>
        <QuestionSVGWrapper>
          <QuestionSVG
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </QuestionSVGWrapper>
        <AnimatedSmallHeading>{t("about-us.heading3")}</AnimatedSmallHeading>
        <SectionParagraph>{t("about-us.text3")}</SectionParagraph>

        <AnimatedSmallHeading>{t("about-us.heading4")}</AnimatedSmallHeading>
        <SectionParagraph>{t("about-us.text4")}</SectionParagraph>

        <AnimatedSmallHeading>{t("about-us.heading5")}</AnimatedSmallHeading>
        <SectionParagraph>{t("about-us.text5")}</SectionParagraph>

        <AnimatedSmallHeading>{t("about-us.heading6")}</AnimatedSmallHeading>
        <SectionParagraph>{t("about-us.text6")}</SectionParagraph>

        <AnimatedSmallHeading>{t("about-us.heading7")}</AnimatedSmallHeading>
        <SectionParagraph>{t("about-us.text7")}</SectionParagraph>
      </WhyUsSection>
    </RouteAnimWrapper>
  );
};

const QuestionSVGWrapper = styled.div`
  width: 65%;
  max-width: 27.5rem;
  margin: 2.5rem auto;
`;

const AboutUsSection = styled.section`
  text-align: justify;

  h3 {
    padding: 0 1.5rem;
  }
`;

const WhyUsSection = styled.section`
  text-align: justify;

  h3 {
    padding: 0 1.5rem;
  }
`;

const SvgBlobWrapper = styled.div`
  position: absolute;
  z-index: -1;

  svg {
    width: 25rem;
    height: 25rem;

    @media (min-width: 68em) {
      width: 60vw;
      height: 60vw;
      max-width: 50rem;
      max-height: 50rem;
    }
  }
`;

export default AboutUs;
