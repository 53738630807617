import React from 'react';
import styled from 'styled-components';

const PrimaryButton = ({ children, className, style,secondary, ...rest }) => (
  <Button secondary={secondary} style={style} className={className} {...rest}>
    {children}
  </Button>
);

const Button = styled.button`
  border: none;
  display: block;
  border-radius: 4px;
  min-width: 7.5rem;
  padding: 0.6rem 1rem;
  color: white;
  font-size: 0.85rem;
  text-align: center;
  background-color: ${({ theme, secondary }) =>
    secondary ? theme.secondary : theme.primary};
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  font-weight: 600;
  text-transform: uppercase;
  :hover {
    transform: translateY(-0.2rem);
    opacity: 0.8;
  }
`;

export default PrimaryButton;
