import React from "react";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import styled from "styled-components";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import SectionParagraph from "components/atoms/SectionParagraph";
import AnimatedSmallHeading from "components/atoms/AnimatedSmallHeading";
import { ReactComponent as BusinessDealSVG } from "assets/images/business_deal.svg";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const ForCompanies = () => { 
  const {t} = useTranslation();

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{t("for_companies.heading1")}</title>
      </Helmet>
      <SvgBlobWrapper style={{ zIndex: -1, top: "30rem", right: "-11rem" }}>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#F0F0F0"
            d="M31.1,-42.3C45.2,-32.5,64.9,-30,67.6,-21.9C70.3,-13.8,56,-0.1,50.6,16.5C45.3,33.1,48.8,52.6,41.9,61.2C35,69.8,17.5,67.4,-0.9,68.6C-19.3,69.8,-38.5,74.7,-48,66.9C-57.6,59.2,-57.4,38.9,-62.6,20.8C-67.7,2.8,-78.2,-13,-76,-26.3C-73.9,-39.6,-59.1,-50.3,-44.4,-59.9C-29.6,-69.4,-14.8,-77.8,-3.2,-73.5C8.5,-69.1,17,-52.1,31.1,-42.3Z"
            transform="translate(100 100)"
          />
        </svg>
      </SvgBlobWrapper>
      <SvgBlobWrapper style={{ zIndex: -1, top: "80rem", left: "-10rem" }}>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#F0F0F0"
            d="M26.5,-48.4C32.4,-37.5,33.9,-26.8,40.9,-17C47.9,-7.3,60.4,1.6,64,12.5C67.6,23.4,62.2,36.4,51.9,40.9C41.6,45.5,26.4,41.6,12.5,48.5C-1.5,55.4,-14,72.9,-27.9,77C-41.7,81.1,-56.9,71.8,-62.2,58.1C-67.5,44.5,-62.8,26.4,-64.3,9.8C-65.8,-6.8,-73.5,-21.9,-70.2,-33.3C-67,-44.7,-52.8,-52.4,-39.3,-59.9C-25.7,-67.4,-12.9,-74.6,-1.3,-72.6C10.3,-70.6,20.6,-59.4,26.5,-48.4Z"
            transform="translate(100 100)"
          />
        </svg>
      </SvgBlobWrapper>
      <SvgBlobWrapper style={{ zIndex: -1, top: "160rem", right: "-12rem" }}>
        <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
          <path
            fill="#F0F0F0"
            d="M52.3,-63.6C67.3,-49.8,78.6,-32.8,77.9,-16.7C77.2,-0.6,64.5,14.7,53.4,28.1C42.4,41.5,33,53.2,18.7,63.7C4.4,74.2,-14.7,83.6,-32.8,81C-50.8,78.4,-67.8,63.7,-67.6,47.2C-67.4,30.7,-50.1,12.5,-43.7,-5C-37.4,-22.4,-42,-39,-36.5,-54.5C-31.1,-70,-15.5,-84.4,1.6,-86.3C18.7,-88.2,37.3,-77.4,52.3,-63.6Z"
            transform="translate(100 100)"
          />
        </svg>
      </SvgBlobWrapper>
      <SvgBlobWrapper style={{ zIndex: -1, top: "210rem", right: "-12rem" }}>
        <svg
          width="50%"
          height="50%"
          viewBox="0 0 400 400"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="200" cy="200" r="175" stroke="#F0F0F0" strokeWidth="50" />
        </svg>
      </SvgBlobWrapper>
      <ForCompaniesSection>
        <AnimatedSectionHeading as="h1">
          {t("for_companies.heading1")}
        </AnimatedSectionHeading>
        <BusinessDealSVGWrapper>
          <BusinessDealSVG height="100%" width="100%" />
        </BusinessDealSVGWrapper>
        <SectionParagraph>{t("for_companies.text1")}</SectionParagraph>
        <SectionParagraph>{t("for_companies.text2")}</SectionParagraph>

        <AnimatedSmallHeading>
          {t("for_companies.heading2")}
        </AnimatedSmallHeading>
        <SectionParagraph>{t("for_companies.text3")}</SectionParagraph>

        <AnimatedSmallHeading>
          {t("for_companies.heading3")}
        </AnimatedSmallHeading>
        <SectionParagraph as="div">
          <ul style={{ padding: 0, listStyle: "none" }}>
            <Type>
              <strong>{t("for_companies.bold_text1")}</strong>{" "}
              {t("for_companies.text4")}
            </Type>
            <Type>
              <strong>{t("for_companies.bold_text2")}</strong>{" "}
              {t("for_companies.text5")}
            </Type>
            <Type>
              <strong>{t("for_companies.bold_text3")}</strong>{" "}
              {t("for_companies.text6")}
            </Type>
            <Type>
              <strong>{t("for_companies.bold_text4")}</strong>{" "}
              {t("for_companies.text7")}
            </Type>
          </ul>
        </SectionParagraph>

        <AnimatedSmallHeading>
          {t("for_companies.heading4")}
        </AnimatedSmallHeading>
        <SectionParagraph>
          <strong>{t("for_companies.bold_text5")}</strong>
        </SectionParagraph>
        <SectionParagraph>{t("for_companies.text8")}</SectionParagraph>
        <SectionParagraph>
          {t("for_companies.text9")}{" "}
          <ClickableText href="tel:509899309">509899309</ClickableText>{" "}
          {t("for_companies.text10")}{" "}
          <ClickableText href="mailto:biuro@lang.pl">
            biuro@lang.pl
          </ClickableText>
        </SectionParagraph>
      </ForCompaniesSection>
    </RouteAnimWrapper>
  );};

const ClickableText = styled.a`
  color: ${({theme}) => theme.primary};
  font-weight: 600;

  :hover {
    opacity: 0.8;
  }
`;

const BusinessDealSVGWrapper = styled.div`
  width: 65%;
  max-width: 27.5rem;
  margin: 2.5rem auto;
`;

const Type = styled.li`
  margin-bottom: 1.5rem;
`;

const ForCompaniesSection = styled.section`
  text-align: justify;

  h3 {
    padding: 0 1.5rem;
  }
`;

const SvgBlobWrapper = styled.div`
  position: absolute;
  z-index: -1;

  svg {
    width: 25rem;
    height: 25rem;

    @media (min-width: 68em) {
      width: 60vw;
      height: 60vw;
      max-width: 50rem;
      max-height: 50rem;
    }
  }
`;

export default ForCompanies;
