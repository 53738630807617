import React from 'react';

import { ReactComponent as HomeIco } from 'assets/icons/home.svg';
import { ReactComponent as AboutUsIco } from 'assets/icons/about-us.svg';
import { ReactComponent as OfferIco } from 'assets/icons/hand-holding-up-a-book.svg';
import { ReactComponent as SkyscrapersIco } from 'assets/icons/skyscrapers.svg';
import { ReactComponent as NewsIco } from 'assets/icons/news.svg';
import { ReactComponent as MailIco } from "assets/icons/mail.svg";
import { ReactComponent as PriceListIco } from "assets/icons/hand-holding-up-a-sack-of-money.svg";

const DEFAULT_PROPS = {
  height: 20,
  width: 20,
};

const Ico = (props) => {
  switch (props.name) {
    case 'home':
      return <HomeIco {...DEFAULT_PROPS} {...props} />;
    case 'about':
      return <AboutUsIco {...DEFAULT_PROPS} {...props} />;
    case 'offer':
      return <OfferIco {...DEFAULT_PROPS} {...props} />;
    case 'firm':
      return <SkyscrapersIco {...DEFAULT_PROPS} {...props} />;
    case 'news':
      return <NewsIco {...DEFAULT_PROPS} {...props} />;
    case 'mail':
      return <MailIco {...DEFAULT_PROPS} {...props} />;
    case 'price':
      return <PriceListIco {...DEFAULT_PROPS} {...props} />;
    default:
      return null;
  }
};

export default Ico;
