import { css } from "styled-components";

export const tokens = {
         image: "e390b69a34a810b3bc8ed8618db541",
       };

export const breakPoints = {
         beforeTablet: "37.5em",
         tablet: "57em",
         laptop: "64em",
       };

export const maxWidthStyle = css`
  @media (min-width: 85em) {
    max-width: 85.375rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

