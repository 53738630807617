import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import MenuList from "components/atoms/MenuList";
import { closeMenu } from "store/settings/settings.slice";
import PrimaryButton from "components/atoms/PrimaryButton";
import { useTranslation } from "react-i18next";

const RenderItem = ({ Icon, route, text, withIcons }) => {
  return (
    <LinkWrapper>
      <StyledLink exact activeClassName="active" to={route}>
        {withIcons && <Icon style={{ marginRight: "1rem" }} />}
        {text}
      </StyledLink>
    </LinkWrapper>
  );
};

const SideDrawer = ({ pathname }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMenuOpen } = useSelector(({ settings }) => settings);

  useEffect(() => {
    setTimeout(() => {
      dispatch(closeMenu());
    }, 200);
  }, [pathname]);

  return (
    <Nav open={isMenuOpen}>
      <StyledMenu withIcons renderItem={RenderItem} />
      <BottomSection>
        <PrimaryButton as={NavLink} to="/test" secondary="1">
          {t("common.test_online").toUpperCase()}
        </PrimaryButton>
        <PrimaryButton as={NavLink} to="/kurs-zapis">
          {t("common.sign_up").toUpperCase()}
        </PrimaryButton>
      </BottomSection>
    </Nav>
  );
};

const StyledMenu = styled(MenuList)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const BottomSection = styled.div`
  min-height: 8rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  align-items: stretch;
  padding: 1rem;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  padding-top: 4.5rem;
  width: 12.75rem;
  z-index: 11;
  background: ${({ theme }) => theme.grayBgColor};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.25s ease-in-out, visibility 0.25s ease-in-out;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    background: ${({ theme }) => theme.grayBgColor};
    height: 100vh;
    width: 100%;
    z-index: -1;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.6);
  }
`;

const LinkWrapper = styled.li`
  width: 100%;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 0rem 2rem 0rem 1rem;
  width: 100%;
  position: relative;
  z-index: 1;
  height: 3.25rem;
  font-weight: 600;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  color: ${({ theme }) => theme.txtColor};
  transition: transform 0.2s ease-in-out;

  svg {
    fill: ${({ theme }) => theme.txtColor};
  }

  &.active {
    color: ${({ theme }) => theme.secondary};
  }

  &.active svg {
    fill: ${({ theme }) => theme.secondary};
  }

  @media not all and (pointer: coarse) {
    :hover {
      transform: translateX(0.25rem);
    }
  }
`;

export default SideDrawer;
