import React, { useState } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import styled from "styled-components";
import { maxWidthStyle, breakPoints } from "constant";
import useInterval from "utils/useInterval";
import { useEffect } from "react";
import Axios from "axios";

const SchoolPicturesCarousel = () => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: true,
    align: "start",
    slidesToScroll: 1,
  });
  const [pictureURLs, setPictureURLs] = useState([]);
  const [stop, setStop] = useState(true);

  useInterval(() => {
    if (!stop && embla) {
      embla.scrollNext();
    }
  }, 7000);

  const stopCarousel = () => {
    setStop(true);
  };

  const playCarousel = () => {
    setStop(false);
  };

  useEffect(() => {
    window.addEventListener("focus", playCarousel);
    window.addEventListener("blur", stopCarousel);

    return () => {
      window.removeEventListener("focus", playCarousel);
      window.removeEventListener("blur", stopCarousel);
    };
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const { data } = await Axios.get(`/api/collections/get/zdjeciaszkoly`);
        if (data?.entries?.[0]?.zdjecia?.length) {
          setPictureURLs(data.entries[0].zdjecia.map((i) => i.path));
        }
      } catch {
        // err
      }
    };
    fetchImages();
  }, []);

  useEffect(() => {
    if (embla && pictureURLs.length) {
      embla.reInit({
        loop: true,
        align: "start",
        slidesToScroll: 1,
      });
      setTimeout(() => {
        setStop(false);
      }, 200);
    }
  }, [embla, pictureURLs]);

  return (
    <Container>
      <StartStopButton type="button" onClick={() => setStop((prev) => !prev)}>
        {stop ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            width="40px"
            height="40px"
            viewBox="0 0 485.74 485.74"
            xmlSpace="preserve"
          >
            <g>
              <g>
                <path d="M242.872,0C108.732,0,0.004,108.736,0.004,242.864c0,134.14,108.728,242.876,242.868,242.876    c134.136,0,242.864-108.736,242.864-242.876C485.736,108.736,377.008,0,242.872,0z M338.412,263.94l-134.36,92.732    c-16.776,11.588-30.584,4.248-30.584-16.316V145.38c0-20.556,13.808-27.9,30.584-16.312l134.32,92.732    C355.136,233.384,355.176,252.348,338.412,263.94z" />
              </g>
            </g>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            width="2.5rem"
            height="2.5rem"
            viewBox="0 0 310.284 310.284"
            xmlSpace="preserve"
          >
            <path d="M155.142,0C69.597,0,0,69.597,0,155.142s69.597,155.142,155.142,155.142s155.142-69.597,155.142-155.142  S240.688,0,155.142,0z M130.392,212.864c0,9.665-7.835,17.5-17.5,17.5s-17.5-7.835-17.5-17.5v-115c0-9.665,7.835-17.5,17.5-17.5  s17.5,7.835,17.5,17.5V212.864z M209.392,212.864c0,9.665-7.835,17.5-17.5,17.5s-17.5-7.835-17.5-17.5v-115  c0-9.665,7.835-17.5,17.5-17.5s17.5,7.835,17.5,17.5V212.864z" />
          </svg>
        )}
      </StartStopButton>
      <EmblaCarouselReact>
        <div style={{ display: "flex" }}>
          {pictureURLs.length !== 0 ? (
            <>
              {pictureURLs.map((url) => (
                <SchoolSlide key={url} picture={url} />
              ))}
            </>
          ) : (
            <>
              <SchoolSlide />
              <SchoolSlide />
              <SchoolSlide />
              <SchoolSlide />
            </>
          )}
        </div>
      </EmblaCarouselReact>
    </Container>
  );
};

const StartStopButton = styled.button`
  border: none;
  opacity: 0.5;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: none;
  z-index: 1;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 100%;
  svg {
    fill: #ffffff;
    width: 2.5rem;
    height: 2.5rem;
  }
`;

const Container = styled.div`
  position: relative;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
  ${maxWidthStyle};
`;

const SchoolSlide = styled.div`
  width: 100%;
  position: relative;
  flex: 0 0 100%;
  height: 35vh;
  background-image: url(${({ picture }) => picture});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center 80%;

  @media (min-width: ${breakPoints.beforeTablet}) {
    height: 65vh;
  }

  ${maxWidthStyle};
`;

export default SchoolPicturesCarousel;
