import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Conversation } from 'assets/images/conversation.svg';
import { ReactComponent as Logo } from 'assets/images/logo-with-text-white.svg';
import { useEffect } from 'react';
import i18n from "settings/i18n";
import { useSelector, useDispatch } from 'react-redux';
import { setIsInitialAnimationVisible } from 'store/settings/settings.slice';

const InitialAnimation = () => {
  const isInitialized = i18n.isInitialized;
  const dispatch = useDispatch();
  const { isInitialAnimationVisible } = useSelector(
    ({ settings }) => settings,
  );
  
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(setIsInitialAnimationVisible(false));
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [isInitialAnimationVisible, dispatch]);

  if(!isInitialAnimationVisible) {
    return null;
  }

  return (
    <>
      {(!isInitialized || isInitialAnimationVisible) && (
        <Wrapper>
          <Logo style={{ maxWidth: "13rem", width: "100%", height: "auto" }} />
          <Conversation />
        </Wrapper>
      )}
    </>
  );};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  padding: 0 2rem;
  background-color: #adb7c9;

  svg:last-child {
    margin-top: 4rem;
    height: auto;
    width: 100%;
    max-width: 40rem;
  }
`;

export default InitialAnimation;
