import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { AnimatePresence } from "framer-motion";
import { theme as th } from "theme";

import Header from "components/molecules/Header";

import AboutUs from "screens/AboutUs";
import ForCompanies from "screens/ForCompanies";
import Home from "screens/Home";
import NoMatch from "screens/404";
import News from "screens/News";
import Article from "screens/News/Article";
import Offer from "screens/Offer";
import Contact from "screens/Contact";
import SideDrawer from "components/organisms/SideDrawer";
import Backdrop from "components/atoms/Backdrop";
import ScrollToTop from "utils/ScrollToTop";
import PriceList from "screens/PriceList";
import FooterComponent from "components/molecules/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import usePrevious from "utils/usePrevious";
import Test from "screens/Test";
import SignUp from "screens/SignUp";
import { Helmet } from "react-helmet";

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();
  const isInitialized = i18n.isInitialized;
  const prevPathName = usePrevious(location.pathname);

  useEffect(() => {
    if (location.hash) {
      let timeout = 600;
      if (prevPathName !== location.pathname) {
        setTimeout(() => {
          const element = document.querySelector(location.hash);
          if (element) {
            const y =
              element.getBoundingClientRect().top - 60 +
              window.pageYOffset;
            window.scrollTo({ top: y, behavior: "smooth" });
          }
        }, timeout);
      }

    }
  }, [location.hash, location.pathname, prevPathName]);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);
        if (element) {
          const y = element.getBoundingClientRect().top - 60 + window.pageYOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }, 2100);
    }
  }, []);

  if (!isInitialized) {
    return null;
  }

  return (
    <ThemeProvider theme={th}>
      <Helmet
        titleTemplate="%s - Szkoła Języków Obcych LANG"
        defaultTitle="Szkoła Języków Obcych LANG"
      >
        <meta
          name="description"
          content="kursy angielski, angielski, Kędzierzyn angielski, kursy językowe angielski, angielski dla dzieci, kursy dla dzieci, angielski dorośli, Business English, kursy maturalne, kursy egzaminacyjne, kursy FC, Unikids Kędzierzyn"
        />
      </Helmet>
      <ScrollToTop />
      <Header />
      <SideDrawer pathname={location.pathname} />
      <Backdrop />
      <Main>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route exact path="/" component={Home} />
            <Route exact path="/o-nas" component={AboutUs} />
            <Route exact path="/oferta" component={Offer} />
            <Route exact path="/cennik" component={PriceList} />
            <Route exact path="/dla-firm" component={ForCompanies} />
            <Route exact path="/aktualnosci" component={News} />
            <Route exact path="/aktualnosci/strona/:id" component={News} />
            <Route exact path="/aktualnosci/:id" component={Article} />
            <Route exact path="/kontakt" component={Contact} />
            <Route exact path="/test" component={Test} />
            <Route exact path="/kurs-zapis" component={SignUp} />
            <Route component={NoMatch} />
          </Switch>
        </AnimatePresence>
      </Main>
      <FooterComponent />
      <ToastContainer autoClose={3000} />
    </ThemeProvider>
  );
}

const Main = styled.main`
  min-height: calc(100vh - 85px);
  transition: 0.2s transform ease-out;
`;

export default App;
