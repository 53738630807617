import React from "react";
import styled from "styled-components";

import { ReactComponent as EmptyStreet } from "assets/images/empty_street.svg";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import { Link } from "react-router-dom";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import { maxWidthStyle } from "constant";
import { Helmet } from "react-helmet";

const NoMatch = () => (
  <RouteAnimWrapper>
    <Helmet>
      <title>Strona nie została odnaleziona!</title>
    </Helmet>

    <Wrapper>
      <AnimatedSectionHeading style={{ marginBottom: 0, fontSize: "4rem" }}>
        UPS!
      </AnimatedSectionHeading>
      <AnimatedSectionHeading style={{ marginTop: "0.5rem", fontSize: "2rem" }}>
        Strona nie została odnaleziona!
      </AnimatedSectionHeading>
      <Button to="/">WRÓĆ NA STRONĘ GŁÓWNĄ</Button>
      <EmptyStreet
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: "-5px",
          height: "auto",
          width: "85%",
          maxWidth: "45rem",
          margin: "1rem auto 0",
        }}
        width="85%"
        height="100%"
      />
    </Wrapper>
  </RouteAnimWrapper>
);

const Wrapper = styled.div`
  ${maxWidthStyle};
  min-height: calc(100vh - 85px);
  position: relative;
`;

const Button = styled(Link)`
  margin: 0 auto;
  border: none;
  display: block;
  border-radius: 4px;
  width: 17.5rem;
  padding: 0.6rem 1rem;
  color: white;
  background-color: ${({ theme, secondary }) =>
    secondary ? theme.secondary : theme.primary};
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2rem;
  align-self: center;

  :hover {
    transform: translateY(-0.2rem);
    opacity: 0.8;
  }
`;

export default NoMatch;
