import React from 'react';
import styled from 'styled-components';
import { breakPoints } from 'constant';

const QUOTE_COLORS = ['#d9d9d9', '#c1c1c1', '#a9a9a9', '#919191', '#797979'];

const TestimonialsSlide = ({ name, text, role, index }) => {
  return (
    <Wrapper>
      <SlideContainer>
        <QuoteIconWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            width="35px"
            height="35px"
            viewBox="0 0 124 124"
            xmlSpace="preserve"
          >
            <g>
              <path
                fill={QUOTE_COLORS[index % QUOTE_COLORS.length]}
                d="M49.8,29.032c3.1-1.3,4.4-5,3-8l-4.9-10.3c-1.4-2.899-4.8-4.2-7.8-2.899c-8.5,3.6-15.8,8.3-21.6,14   C11.4,28.532,6.6,36.232,4,44.732c-2.6,8.601-4,20.3-4,35.2v30.7c0,3.3,2.7,6,6,6h39.3c3.3,0,6-2.7,6-6v-39.3c0-3.301-2.7-6-6-6   H26.5c0.2-10.101,2.6-18.2,7-24.301C37.1,36.133,42.5,32.133,49.8,29.032z"
              />
              <path
                fill={QUOTE_COLORS[index % QUOTE_COLORS.length]}
                d="M120.4,29.032c3.1-1.3,4.399-5,3-8l-4.9-10.199c-1.4-2.9-4.8-4.2-7.8-2.9c-8.4,3.6-15.601,8.3-21.5,13.9   c-7.101,6.8-12,14.5-14.601,23c-2.6,8.399-3.899,20.1-3.899,35.1v30.7c0,3.3,2.7,6,6,6H116c3.3,0,6-2.7,6-6v-39.3   c0-3.301-2.7-6-6-6H97.1c0.2-10.101,2.601-18.2,7-24.301C107.7,36.133,113.1,32.133,120.4,29.032z"
              />
            </g>
          </svg>
        </QuoteIconWrapper>
        <TestimonialText>{text}</TestimonialText>
        {name && <TestimonialAuthor>{name}</TestimonialAuthor>}
        {role && <TestimonialRole>{role}</TestimonialRole>}
      </SlideContainer>
    </Wrapper>
  );
};

const QuoteIconWrapper = styled.div`
  opacity: 0.7;
`;

const Wrapper = styled.div`
  position: relative;
  flex: 0 0 100%;

  @media (min-width: ${breakPoints.beforeTablet}) {
    flex: 0 0 50.01%;
  }

  @media (min-width: ${breakPoints.tablet}) {
    flex: 0 0 33.34%;
  }
`;

const SlideContainer = styled.div`
  position: relative;
  background-color: #ffffff;
  margin: 1.5rem;
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 16px 24px -16px rgba(121,121,121,0.5);
`;

const TestimonialText = styled.p`
  color: ${({ theme }) => theme.txtColor};
  font-size: 1rem;
  line-height: 1.8;
  font-style: italic;

  ::after {
    content: '';
    height: 3px;
    width: 70%;
    border-radius: 3px;
    display: block;
    margin: 1rem auto;
    background-color: #f0f0f0;
  }
`;

const TestimonialAuthor = styled.h4`
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: ${({ theme }) => theme.secondary};
  text-align: center;
`;

const TestimonialRole = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.txtColor};
  text-align: center;
  margin: 0;
`;

export default TestimonialsSlide;
