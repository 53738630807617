import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // use cache
  .use(Cache)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // backend: {
    //   loadPath: 'https://www.google.com/locales/{{lng}}.json',
    //   crossDomain: true,
    // },
    fallbackLng: 'pl',
    debug: true,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain',
      ],
      caches: ['localStorage'],
    },
    cache: {
      enabled: true,
      prefix: 'i18next_res_',
      // expiration
      expirationTime: 7 * 24 * 60 * 60 * 1000,
      // language versions
      versions: {
        pl: 'v0.2',
        en: 'v0.2',
      },
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
