import React from 'react';
import styled from 'styled-components';
import { ReactComponent as NoImage } from 'assets/images/no_image.svg';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { pl, enGB } from 'date-fns/locale';
import { Link } from 'react-router-dom';

const ArticlePreview = ({ image, title, title_en, text, text_en, date, id, fromArticles = false }) => {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n?.language.substring(0, 2).toLowerCase() || 'pl';

  const formattedDate = (d, dateFormat) =>
    format(d, dateFormat, {
      locale: activeLanguage === 'pl' ? pl : enGB,
    });

  const createMarkup = () => ({ __html: activeLanguage === 'pl' ? text : (text_en || text) });

  const createUrl = (t, i) =>
    `/aktualnosci/${t
      .toLowerCase()
      .replace(/ę/gi, 'e')
      .replace(/ż/gi, 'z')
      .replace(/ó/gi, 'o')
      .replace(/ł/gi, 'l')
      .replace(/ć/gi, 'c')
      .replace(/ś/gi, 's')
      .replace(/ź/gi, 'z')
      .replace(/ń/gi, 'n')
      .replace(/ą/gi, 'a')
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')}-${i}`;

  return (
    <WrapperLink
      to={{
        pathname: createUrl(title, id),
        state: {
          fromArticles
        },
      }}
    >
      <Wrapper>
        <ImageWrapper image={image}>{!image && <NoImage />}</ImageWrapper>
        <Mid>
          <header>
            {title && (
              <Title>
                {activeLanguage === "pl" ? title : title_en || title}
              </Title>
            )}
          </header>
          {text && <ArticleText dangerouslySetInnerHTML={createMarkup()} />}
        </Mid>
        <Bottom>
          {date && (
            <DateComponent dateTime={formattedDate(date, "yyyy-MM-dd")}>
              {formattedDate(date, "dd MMM yyy")}
            </DateComponent>
          )}
          <ReadMore>{t("common.read_more")}</ReadMore>
        </Bottom>
      </Wrapper>
    </WrapperLink>
  );
};

const DateComponent = styled.time`
  font-size: 1rem;
  margin-left: 1rem;
  font-weight: 600;
  color: ${({ theme }) => theme.txtColor};
`;

const ArticleText = styled.p`
  margin: 0;
  font-size: 1rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.txtColor};
  position: relative;
  overflow: hidden;
  max-height: 5.6rem;
  & p {
    margin: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.txtColor};
  }

  & & img {
    display: none;
  }
  /* &::after {
    content: '';
    display: block;
    width: 100%;
    height: calc(100% + 2.8rem);
    background: linear-gradient(0deg, #ffffff 25%, transparent);
    position: absolute;
    bottom: -2.8rem;
    left: 0;
  } */
`;

const WrapperLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  flex: 1;
  transition: transform 0.2s ease-in-out;

  &:hover {
    opacity: 0.9;
    transform: translateY(-0.2rem);
  }
`;

const ReadMore = styled.span`
  border: none;
  background: none;
  font-size: 1rem;
  padding: 1rem;
  color: ${({ theme }) => theme.secondary};
`;

const Title = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  color: ${({ theme }) => theme.secondary};
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 12rem;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
  background-color: #e9e9e9;
  overflow: hidden;
  position: relative;

  svg {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    max-height: 7rem;
  }
`;

const Mid = styled.div`
  width: 100%;
  flex: 1;
  padding: 1rem;
`;

const Bottom = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.article`
  background-color: white;
  box-shadow: 0 16px 24px -16px rgba(59, 142, 184, 0.25);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
`;

export default ArticlePreview;
