import React from 'react';
import styled from 'styled-components';
import OfferTile from 'components/atoms/OfferTile';
import { breakPoints } from 'constant';

const OfferTileGrid = () => {
  const OFFERS = [
    {
      heading: "Kursy grupowe",
      route: "/oferta#kursy-grupowe",
      text:
        "Kursy grupowe dla dzieci, młodzieży i\xa0dorosłych na wszystkich poziomach zaawansowania.",
    },
    {
      heading: "Tryb indywidualny",
      route: "/oferta#tryb-indywidualny",
      text:
        "Nauka własnym tempem! Elastyczny grafik oraz materiały dostosowane do potrzeb.",
    },
    {
      heading: "Grupy zamknięte",
      route: "/oferta#tryb-indywidualny",
      text:
        "Idealne rozwiązanie dla osób, które chciałyby podjąć naukę w gronie znajomych.",
    },
    {
      heading: "Nauka online",
      route: "/oferta#kursy-online",
      text:
        "Nauka bez wychodzenia z\xa0domu! Kursy oparte na specjalnie przygotowanych materiałach oraz platformach.",
    },
    {
      heading: "Kursy dla firm",
      route: "/dla-firm",
      text:
        "Nauka języka angielskiego z native speakerami w siedzibie firmy lub budynku naszej szkoły.",
    },
  ];
  return (
    <Grid>
      {OFFERS.map(({ heading, text, route }) => (
        <OfferTile key={heading} route={route} heading={heading} text={text} />
      ))}
    </Grid>
  );
};

const Grid = styled.div`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-auto-flow: dense;

  @media (min-width: ${breakPoints.beforeTablet}) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: ${breakPoints.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default OfferTileGrid;
