import React, { useEffect, useState } from "react";
import RouteAnimWrapper from "components/atoms/RouteAnimWrapper";
import styled from "styled-components";
import AnimatedSectionHeading from "components/atoms/AnimatedSectionHeading";
import Axios from "axios";
import { useForm } from "react-hook-form";
import PrimaryButton from "components/atoms/PrimaryButton";
import { maxWidthStyle, breakPoints } from "constant";
import Input from "components/atoms/Input";

import { ReactComponent as MailboxSVG } from "assets/images/mail_box.svg";
import { useTranslation } from "react-i18next";
import Loader from "components/atoms/Loader";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const LETTER_ENUM = ["a", "b", "c", "d", "e"];

const Test = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const {
    register: userRegister,
    handleSubmit: userHandleSubmit,
    errors: userErrors,
  } = useForm();
  const [questions, setQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [points, setPoints] = useState(0);
  const [sending, setSending] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [step, setStep] = useState(1);
  const [results, setResults] = useState(null);

  useEffect(() => {
    Axios.get("/api/collections/get/test")
      .then(({ data }) => data.entries)
      .then((data) => {
        setQuestions(data);
        setCorrectAnswers(data.map((item) => item.correct_answer));
      });
  }, []);

  const onSubmit = (data) => {
    const userAnswers = Object.values(data);
    const score = userAnswers.reduce((acc, answer, index) => {
      if (answer === correctAnswers[index]) {
        acc += 1;
      }
      return acc;
    }, 0);
    setPoints(score);
    setFormDisabled(true);
    setStep(2);
    window.scrollTo(0, 0);
    const results = Object.entries(data).map((item, index) => ({
      question: questions[index].question,
      correct_answer: `${questions[index].correct_answer}) ${
        questions[index].answers[
          LETTER_ENUM.findIndex(
            (letter) => letter === questions[index].correct_answer,
          )
        ].value
      }`,
      user_answer: `${item[1]}) ${
        questions[index].answers[
          LETTER_ENUM.findIndex((letter) => letter === item[1])
        ].value
      }`,
      is_correct: item[1] === questions[index].correct_answer
    }));

    setResults(results);
  };

  const sendEmail = (data) => {
    setSending(true);
    Axios.post("/test", {
      user_data: data,
      results,
      points
    }).then(() => {
      history.push("/");
      toast("🚀 Pomyślnie wysłano E-Mail");
    })
    .catch(() => {
      toast("Coś poszło nie tak...", { type: "error" });
    })
    .finally(() => {
      setSending(false);
    });
  };

  return (
    <RouteAnimWrapper>
      <Helmet>
        <title>{step === 1 ? t("test.heading1") : t("test.heading2")}</title>
      </Helmet>
      <AnimatedSectionHeading
        style={{ padding: "0 1.5rem", lineHeight: "1.1" }}
        as="h1"
      >
        {step === 1 ? t("test.heading1") : t("test.heading2")}
      </AnimatedSectionHeading>
      {step === 1 && (
        <Section>
          <TestForm onSubmit={handleSubmit(onSubmit)}>
            {!!questions?.length && (
              <>
                {questions.map((item, index) => (
                  <QuestionWrapper key={item._id}>
                    <Question error={!!errors[`question${index + 1}`]}>
                      {index + 1}. {item.question}
                    </Question>
                    {item.answers.map(({ value }, answerIndex) => (
                      <Answer key={value}>
                        <input
                          disabled={formDisabled}
                          type="radio"
                          //`question${index + 1}`
                          name={`question${index + 1}`}
                          value={LETTER_ENUM[answerIndex]}
                          ref={register({ required: true })}
                        />
                        {LETTER_ENUM[answerIndex]}
                        {") "}
                        {value}
                      </Answer>
                    ))}
                  </QuestionWrapper>
                ))}
                <div style={{ gridColumn: "1/-1" }}>
                  <PrimaryButton type="submit">
                    {t("common.send").toUpperCase()}
                  </PrimaryButton>
                </div>
              </>
            )}
          </TestForm>
        </Section>
      )}
      {step === 2 && (
        <Section>
          <SendMailWrapper>
            <UserForm onSubmit={userHandleSubmit(sendEmail)}>
              <UserFormGrid>
                <Input
                  label={t("common.first_name")}
                  error={userErrors.first_name}
                  placeholder="Jan"
                  type="text"
                  name="first_name"
                  register={userRegister({
                    required: t("validation.required"),
                  })}
                />
                <Input
                  label={t("common.last_name")}
                  error={userErrors.last_name}
                  placeholder="Kowalski"
                  type="text"
                  name="last_name"
                  register={userRegister({
                    required: t("validation.required"),
                  })}
                />
                <Input
                  label={t("common.age")}
                  error={userErrors.age}
                  placeholder="25"
                  maxLength="2"
                  max="99"
                  min="1"
                  type="number"
                  name="age"
                  register={userRegister({
                    required: t("validation.required"),
                    pattern: {
                      value: /^[1-9][0-9]?$/,
                      message: t("validation.age"),
                    },
                  })}
                />
                <Input
                  label={t("common.email")}
                  error={userErrors.email}
                  placeholder="example@mail.com"
                  type="email"
                  name="email"
                  register={userRegister({
                    required: t("validation.required"),
                    pattern: {
                      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: t("validation.email"),
                    },
                  })}
                />
                <Input
                  label={t("common.phone_number")}
                  error={userErrors.phone}
                  placeholder="123456789"
                  maxLength="9"
                  type="tel"
                  name="phone"
                  register={userRegister({
                    pattern: {
                      value: /[0-9]{9}/,
                      message: t("validation.phone_number"),
                    },
                  })}
                />
              </UserFormGrid>
              <Points>
                {t("test.score")}{" "}
                <Important>
                  {points} {t("test.points")}
                </Important>
              </Points>
              {sending ? (
                <Loader style={{ marginTop: "1.5rem", marginLeft: "1rem" }} />
              ) : (
                <PrimaryButton style={{ marginTop: "1.5rem" }} type="submit">
                  {t("common.send").toUpperCase()}
                </PrimaryButton>
              )}
            </UserForm>
            <MailboxSVGWrapper>
              <MailboxSVG height="100%" width="100%" />
            </MailboxSVGWrapper>
          </SendMailWrapper>
        </Section>
      )}
    </RouteAnimWrapper>
  );
};

const MailboxSVGWrapper = styled.div`
  width: 100%;
  max-width: 30rem;
  padding: 1.25rem;
  @media (min-width: 45em) {
    padding: 2.5rem;
    width: 40%;
  }

  @media (min-width: ${breakPoints.laptop}) {
    width: 50%;
  }
`;

const SendMailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const UserFormGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
`;

const UserForm = styled.form`
  width: 100%;
  @media (min-width: 45em) {
    width: 60%;
  }
  @media (min-width: ${breakPoints.laptop}) {
    width: 50%;
  }
`;

const TestForm = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 50rem;
  grid-gap: 0.5rem;
  margin: 0 auto;

  @media (min-width: ${breakPoints.beforeTablet}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Important = styled.span`
  color: ${({ theme }) => theme.secondary};
`;

const Points = styled.h4`
  color: ${({ theme }) => theme.txtColor};
  padding: 1.5rem 0 0.75rem;
`;

const QuestionWrapper = styled.div`
  padding: 0.75rem 0;
`;

const Answer = styled.label`
  color: ${({ theme }) => theme.txtColor};
  display: flex;
  align-items: center;
  padding: 0.25rem 1.1rem;
  input {
    margin-right: 0.5rem;
  }
`;

const Question = styled.h3`
  color: ${({ theme, error }) => (error ? theme.error : theme.txtColor)};
  font-size: 1rem;
`;

const Section = styled.section`
  padding: 0 1.5rem 1.5rem;
  ${maxWidthStyle};
`;

export default Test;
